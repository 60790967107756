import React, { Fragment } from 'react';
import HOCProtectedPage from '../../HOCProtectedPage';
import TopBar from '../../../pageComponents/topbar/TopBar';
import AppContext from '../../../app/AppContext';

import UsuariosPage from './UsuariosPage';

function UsuariosPageHOC() {
  return (
    <HOCProtectedPage>
      <AppContext.Consumer>
        {context => (
          <Fragment>
            <TopBar />
            <UsuariosPage
              listaUsuarios={context.listaUsuarios}
              onCrudUsuario={context.onCrudUsuario}
            />
          </Fragment>
        )}
      </AppContext.Consumer>
    </HOCProtectedPage>
  );
}

export default UsuariosPageHOC;
