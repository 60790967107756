const UrlPages = {
  LOGIN: '/',
  SALIR: '/logout',
  DASHBOARD: '/dashboard',
  VER_SOL: '/solicitud/:id',
  MIS_SOLICITUDES: '/mis_solicitudes_index',
  ALL_SOLICITUDES: '/all_solicitudes_index',
  CUENTA: '/cuenta',
  LOGOUT: '/logout',
  MSG_INDEX: '/msg_index',

  ADM_SOLESTATUS_INDEX: '/adm_solestatus_index',

  ADM_SOLTIPO_INDEX: '/adm_soltipo_index',
  ADM_SOLTIPO_CRUD: '/adm_soltipo_crud',

  ADM_USUARIO_INDEX: '/adm_usuario_index',
  ADM_USUARIO_CRUD: '/adm_usuario_crud',

  ADM_PERMISO_VENDEDOR_INDEX: '/adm_permisovendedor_index',
  ADM_PERMISO_VENDEDOR_CRUD: '/adm_permisovendedor_crud',

  ADM_PERMISO_MC_INDEX: '/adm_permisomc_index',
  ADM_PERMISO_MC_CRUD: '/adm_permisomc_crud',

  ADM_AREAVENTAS_INDEX: '/adm_areaventas_index',
  ADM_AREAVENTAS_CRUD: '/adm_areaventas_crud'
};

export default UrlPages;
