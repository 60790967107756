import React, { Fragment } from 'react';
import HOCProtectedPage from '../../HOCProtectedPage';
import TopBar from '../../../pageComponents/topbar/TopBar';
import AppContext from '../../../app/AppContext';
import SolicitudTipoPage from './SolicitudTipoPage';

function SolicitudTipoPageHOC() {
  return (
    <HOCProtectedPage>
      <AppContext.Consumer>
        {context => (
          <Fragment>
            <TopBar />
            <SolicitudTipoPage
              listaSolTipo={context.listaSolTipo}
              onCrudSolTipo={context.onCrudSolTipo}
            />
          </Fragment>
        )}
      </AppContext.Consumer>
    </HOCProtectedPage>
  );
}

export default SolicitudTipoPageHOC;
