import React from 'react';

import ASolicitudesPage from './ASolicitudesPage';

function AllSolicitudesPage({ listaSol, onCrudSol }) {
  return (
    <ASolicitudesPage
      titulo="Solicitudes de los Últimos 2 Meses"
      listaSol={listaSol}
      onCrudSol={onCrudSol}
    />
  );
}

export default AllSolicitudesPage;
