// const urlApiAWS = 'https://ao4t5yy1ei.execute-api.us-west-2.amazonaws.com/dev'; //<-- es nuestro sitio backend
const urlApiAWS = 'https://n9bmj8jcg8.execute-api.us-east-1.amazonaws.com/dev'; //<-- 2021 - cuenta de beryhuert
const urlLocal = 'http://localhost:3400';

const isLocal = false;

const configApi = {
  urlBase: isLocal ? urlLocal : urlApiAWS,
  nombreHeaderAuthorization: 'x-controlsol-token',
  urlWS: 'https://aws.productividadti.com.mx:3600'
};

export default configApi;
