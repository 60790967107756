import React, { Fragment } from 'react';
import AreaVentasPage from './AreaVentasPage';

function DetalleUXA({ listaUXArea, tipoUsuario, tituloU }) {
  let ol = (
    <ul>
      <li className="textoLight">NA</li>
    </ul>
  );

  const listaTipo = listaUXArea.filter(row => {
    return row.tipoUsuario === tipoUsuario;
  });

  if (listaTipo && listaTipo.length > 0) {
    const lista = listaTipo.map((itemAsignacion, index) => {
      return (
        <li title={itemAsignacion._id} key={itemAsignacion._id}>
          <i className="fas fa-user mrBTN" />
          {itemAsignacion.usuario}
        </li>
      );
    });
    ol = <ul>{lista}</ul>;
  }
  return (
    <Fragment>
      <p className="tituloRow">{tituloU}</p>
      {ol}
    </Fragment>
  );
}

export default DetalleUXA;
