import React, { Component } from 'react';
import DataService from '../../../services/dataService/DataService';
import LibToast from '../../../lib/LibToast';

import Table from '../../../pageComponents/table/Table';
import SolicitudEstatusTr from './SolicitudEstatusTr';

class SolicitudEstatusPage extends Component {
  constructor(props, context) {
    super(props, context);
    this.state = {
      listaSolEstatus: []
    };
  }

  async componentDidMount() {
    const respuesta = await DataService.Mc.SolEstatus.Index();

    if (!respuesta.success) {
      LibToast.alert(respuesta.msg);
      return;
    }
    LibToast.success('Datos recibidos Estatus de las Solicitudes');

    this.setState({ listaSolEstatus: respuesta.data.lista });
  }

  render() {
    const { listaSolEstatus } = this.state;
    const listaHead = ['Estatus', 'Posición'];

    const fn = (row, index, idLast) => {
      const key = 'k' + row._id;

      return <SolicitudEstatusTr index={index} row={row} key={key} />;
    };

    return (
      <Table
        titulo="Estatus de las Solicitudes"
        listaHead={listaHead}
        listaRows={listaSolEstatus}
        fnRenderRow={fn}
      />
    );
  }
}

export default SolicitudEstatusPage;
