import React, { Fragment } from 'react';
import { Link } from 'react-router-dom';
import UrlPages from '../../../pages/UrlPages';
import { Dropdown } from 'react-materialize';

function FactoryMenuMC(isMcAdmin, isSuperAdmin) {
  if (!isMcAdmin && !isSuperAdmin) {
    return null;
  }

  const listaMenu = [];

  if (isSuperAdmin) {
    listaMenu.push(
      <Link to={UrlPages.ADM_SOLTIPO_INDEX} key="tiposol">
        Tipo de Solicitud
      </Link>
    );
    listaMenu.push(
    
    );
  }


  return (
    <>
      <li><Link to={UrlPages.ADM_USUARIO_INDEX}  >    Usuarios  </Link></li>
      <li><Link to={UrlPages.ADM_SOLTIPO_INDEX} key="tiposol">    Tipo de Solicitud  </Link></li>
      <li><Link to={UrlPages.ADM_SOLESTATUS_INDEX} key="estatus">    Estatus de Solicitudes  </Link></li>
      <li><Link to={UrlPages.ADM_PERMISO_VENDEDOR_INDEX} key="pv">   Permiso de Vendedor </Link></li>
      <li><Link to={UrlPages.ADM_PERMISO_MC_INDEX} key="mc">    Permiso de MC  </Link></li>
      <li><Link to={UrlPages.ADM_AREAVENTAS_INDEX} key="area">    Areas de Venta /Tiendas  </Link></li>
    </>
  );
}

export default FactoryMenuMC;
