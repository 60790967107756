import React, { Fragment } from 'react';
import HOCProtectedPage from '../HOCProtectedPage';
import TopBar from '../../pageComponents/topbar/TopBar';

import AppContext from '../../app/AppContext';
import MsgPage from './MsgPage';

function MsgPageHOC() {
  return (
    <HOCProtectedPage>
      <AppContext.Consumer>
        {context => (
          <Fragment>
            <TopBar />
            <MsgPage listaMsg={context.listaMsg} />
          </Fragment>
        )}
      </AppContext.Consumer>
    </HOCProtectedPage>
  );
}

export default MsgPageHOC;
