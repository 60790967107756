import configApi from '../configApi';
import ApiReqJson from '../ApiReqJson';

const ope = {
  index: 'index',
  create: 'create',
  read: 'read',
  update: 'update',
  delete: 'delete'
};

const apiMc = {
  dataAuxiliar: async () => {
    const url = configApi.urlBase + `/mc/data_auxiliar`;
    return await ApiReqJson.requestGET(url);
  },
  getSol: async id => {
    const url = configApi.urlBase + `/mc/sol?id=${id}`;
    return await ApiReqJson.requestGET(url);
  },
  deleteSol: async id => {
    const url = configApi.urlBase + `/mc/sol_crud`;

    const dataObject = {
      ope: ope.delete,
      data: {
        id
      }
    };
    return await ApiReqJson.requestPOST(url, dataObject);
  },
  updateSol: async (id, dataUpdate) => {
    const url = configApi.urlBase + `/mc/sol_crud`;

    const dataObject = {
      ope: ope.update,
      data: {
        ...dataUpdate,
        id
      }
    };
    return await ApiReqJson.requestPOST(url, dataObject);
  },
  aprobarSol: async (id, comentario) => {
    const url = configApi.urlBase + `/mc/sol_crud`;

    const dataObject = {
      ope: 'aprob',
      data: {
        id,
        comentario
      }
    };

    return await ApiReqJson.requestPOST(url, dataObject);
  },
  rechazarSol: async (id, comentario) => {
    const url = configApi.urlBase + `/mc/sol_crud`;

    const dataObject = {
      ope: 'rechazada',
      data: {
        id,
        comentario
      }
    };
    return await ApiReqJson.requestPOST(url, dataObject);
  },
  comentario: async (id, comentario) => {
    const url = configApi.urlBase + `/mc/sol_crud`;

    const dataObject = {
      ope: 'comentario',
      data: {
        id,
        comentario
      }
    };
    return await ApiReqJson.requestPOST(url, dataObject);
  },
  rotarImagen: async (sol, itemImg, rotacion = 'R') => {
    const url = configApi.urlBase + `/mc/sol_rotar_img`;

    const dataObject = {
      ym: sol.ym,
      idSol: sol._id,
      file: itemImg.file,
      rotacion
    };

    return await ApiReqJson.requestPOST(url, dataObject);
  },
  AreaVentas: {
    Index: async () => {
      const url = configApi.urlBase + `/mc/areaventas_read?ope=index`;
      return await ApiReqJson.requestGET(url);
    },
    Detalle: async id => {
      const url = configApi.urlBase + `/mc/areaventas_read?ope=read&id=${id}`;
      return await ApiReqJson.requestGET(url);
    },
    Update: async (id, dataUpdate) => {
      const url = configApi.urlBase + `/mc/areaventas_crud`;

      const dataObject = {
        ope: ope.update,
        data: {
          id,
          ...dataUpdate
        }
      };

      return await ApiReqJson.requestPOST(url, dataObject);
    },
    Delete: async id => {
      const url = configApi.urlBase + `/mc/areaventas_crud`;
      const dataObject = {
        ope: ope.delete,
        data: {
          id
        }
      };

      return await ApiReqJson.requestPOST(url, dataObject);
    },
    Create: async dataCreate => {
      const url = configApi.urlBase + `/mc/areaventas_crud`;
      const dataObject = {
        ope: ope.create,
        data: {
          ...dataCreate
        }
      };

      return await ApiReqJson.requestPOST(url, dataObject);
    }
  },
  PermisoMC: {
    Index: async () => {
      const url = configApi.urlBase + `/mc/permisomc_read?ope=index`;
      return await ApiReqJson.requestGET(url);
    },
    IndexFull: async () => {
      const url = configApi.urlBase + `/mc/permisomc_read?ope=indexfull`;
      return await ApiReqJson.requestGET(url);
    },
    FromUsuario: async id => {
      const url =
        configApi.urlBase + `/mc/permisomc_read?ope=fromusuario&id=${id}`;
      return await ApiReqJson.requestGET(url);
    },
    Update: async (id, dataUpdate) => {
      const url = configApi.urlBase + `/mc/permisomc_crud`;

      const dataObject = {
        ope: ope.update,
        data: {
          id,
          ...dataUpdate
        }
      };

      return await ApiReqJson.requestPOST(url, dataObject);
    },
    Delete: async id => {
      const url = configApi.urlBase + `/mc/permisomc_crud`;
      const dataObject = {
        ope: ope.delete,
        data: {
          id
        }
      };

      return await ApiReqJson.requestPOST(url, dataObject);
    },
    Create: async dataCreate => {
      const url = configApi.urlBase + `/mc/permisomc_crud`;
      const dataObject = {
        ope: ope.create,
        data: {
          ...dataCreate
        }
      };

      return await ApiReqJson.requestPOST(url, dataObject);
    }
  },
  PermisoV: {
    IndexFull: async () => {
      const url = configApi.urlBase + `/mc/permisov_read?ope=indexfull`;
      return await ApiReqJson.requestGET(url);
    },
    Detalle: async id => {
      const url = configApi.urlBase + `/mc/permisov_read?ope=read&id=${id}`;
      return await ApiReqJson.requestGET(url);
    },
    FromUsuario: async id => {
      const url =
        configApi.urlBase + `/mc/permisov_read?ope=fromusuario&id=${id}`;
      return await ApiReqJson.requestGET(url);
    },
    Update: async (id, dataUpdate) => {
      const url = configApi.urlBase + `/mc/permisov_crud`;

      const dataObject = {
        ope: ope.update,
        data: {
          id,
          ...dataUpdate
        }
      };

      return await ApiReqJson.requestPOST(url, dataObject);
    },
    Delete: async id => {
      const url = configApi.urlBase + `/mc/permisov_crud`;
      const dataObject = {
        ope: ope.delete,
        data: {
          id
        }
      };

      return await ApiReqJson.requestPOST(url, dataObject);
    },
    Create: async dataCreate => {
      const url = configApi.urlBase + `/mc/permisov_crud`;
      const dataObject = {
        ope: ope.create,
        data: {
          ...dataCreate
        }
      };

      return await ApiReqJson.requestPOST(url, dataObject);
    }
  },
  SolEstatus: {
    Index: async () => {
      const url = configApi.urlBase + `/mc/sol_estatus_read?ope=index`;
      return await ApiReqJson.requestGET(url);
    }
  },


  getUploadS3Url: async (idSol, idTipoDoc, fileExt) => {
    const url = configApi.urlBase + `/mc/get_uploads3_url?idSol=${idSol}&idTipoDoc=${idTipoDoc}&fileExt=${fileExt}`;
    return await ApiReqJson.requestGET(url);
  },
  getS3Url: async (key) => {
    const url = configApi.urlBase + `/mc/get_s3_url?key=${key}`;
    return await ApiReqJson.requestGET(url);
  },


};

export default apiMc;
