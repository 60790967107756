import React, { Component } from 'react';
import DataService from '../../../services/dataService/DataService';
import LibToast from '../../../lib/LibToast';
import Table from '../../../pageComponents/table/Table';
import PermisoMCTr from './PermisoMCTr';
import LibMsgFlash from '../../../services/msgFlash/LibMsgFlash';

//import AreaVentasTrEdit from './AreaVentasTrEdit';

class PermisoMCPage extends Component {
  constructor(props, context) {
    super(props, context);
    this.state = {
      listaPermisoMC: [],
      idLast: null
    };
  }

  async componentDidMount() {
    const respuesta = await DataService.Mc.PermisoMC.IndexFull();

    if (!respuesta.success) {
      LibToast.alert(respuesta.msg);
      return;
    }

    LibToast.success('Datos recibidos Permiso MC');

    this.setState({ listaPermisoMC: respuesta.data.lista });

    const idFlash = LibMsgFlash.getIdLast();

    if (idFlash) {
      const dataFlash = LibMsgFlash.getMsg();
      this.setState({ idLast: idFlash });
      setTimeout(() => {
        LibToast.fromDataFlash(dataFlash);
      }, 300);
    }
  }

  render() {
    const { listaPermisoMC, idLast } = this.state;

    const listaHead = [
      { label: '', claseCSS: 'tdcmd' },
      'Usuario',
      'email',
      'Tiene Permiso',
      'Permiso'
    ];

    const fn = (row, index, idLast) => {
      const key = 'k' + row._id;

      return <PermisoMCTr index={index} row={row} key={key} idLast={idLast} />;
    };

    return (
      <Table
        titulo="Permisos de Mesa de Control"
        listaHead={listaHead}
        listaRows={listaPermisoMC}
        fnRenderRow={fn}
        idLast={idLast}
      />
    );
  }
}

export default PermisoMCPage;
