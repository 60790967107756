import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import EstatusSol from '../../pageComponents/EstatusSol';
import { Button } from 'react-materialize';

import './solicitudes.css';

class TrSolicitud extends Component {
  onDelete = row => {
    this.props.onShowModal.delete(row);
  };

  render() {
    const { row } = this.props;

    const estatus = EstatusSol.getLabelFromEstatus(row.idEstatus);

    const id = row._id;

    const urlSol = '/solicitud/' + id;

    const BtnDel =
      row.idEstatus === EstatusSol.ID_APROBADA ? null : (
        <Button
          className="red btn-small"
          title="Eliminar"
          onClick={event => this.onDelete(row)}
        >
          <i className="fas fa-trash" />
        </Button>
      );

    const ComentarioLi = row.listaCom.map((item, index) => {
      return (
        <li key={index}>
          {item.comentario} {item.fecha} {item.nick}
        </li>
      );
    });

    let iconIsUpdatedFromWS = null;
    if (row.isUpdatedFromWS) {
      iconIsUpdatedFromWS = (
        <div title="Actualización de Estatus" className="labelUpdatedWS">
          <i className="fas fa-exclamation-circle" />
          Actualizado
        </div>
      );
    }

    const Comentario = <ul>{ComentarioLi}</ul>;

    const listaData = [];

    if (row.listaInfo) {
      row.listaInfo.forEach(item => {
        listaData.push({ propiedad: item.nombre, valor: item.valor });
      });
    }

    const listaElemInfo = listaData.map((item, index) => {
      return (
        <li title={item.propiedad} key={index}>
          {item.valor}
        </li>
      );
    });
    return (
      <tr>
        <td>
          <Link to={urlSol} className="waves-effect waves-light btn-small">
            <i className="fas fa-edit" />
          </Link>
        </td>
        <td>
          {estatus}
          {iconIsUpdatedFromWS}
        </td>
        <td>
          <span className="labHora">
            <i className="fa fa-clock" />
            {row.fregHora}
          </span>
          <span className="labFecha">{row.fregDMY}</span>
        </td>
        <td>{row.tipo}</td>

        <td className="text-center">
          <span className="labNombreVendedor" title="Nombre del Vendedor">
            <i className="fa fa-user" />
            {row.nick}
          </span>
          <span className="labAreaVentas" title="Tienda/Distribuidor">
            {row.area}
          </span>
        </td>
        <td>
          <ul>
            <li title="Nombre del Cliente">
              <label className="labNombreCliente">{row.nombreCliente}</label>
            </li>
            {listaElemInfo}
          </ul>
        </td>
        <td title="Número de Fotos">
          <i className="fas fa-camera mrBTN" />
          {row.listaImg.length}
        </td>
        <td>{Comentario}</td>
        <td>{BtnDel}</td>
      </tr>
    );
  }
}

export default TrSolicitud;
