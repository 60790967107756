import React, { Component, Fragment } from 'react';
import { Button, Textarea } from 'react-materialize';
import ComSol from './ComSol';
import LibToast from '../../lib/LibToast';
import DataService from '../../services/dataService/DataService';
import UrlPages from '../UrlPages';
import LibMsgFlash from '../../services/msgFlash/LibMsgFlash';
import LibMsgWSService from '../../services/msgWSService/LibMsgWSService';
import './SolDictamen.css';

class SolDictamen extends Component {
  constructor(props, context) {
    super(props, context);
    this.state = {
      newCom: '',
      isModoDictaminar: false,
      isAprobarEnProceso: false,
      listaCom: props.sol.listaCom
    };

    this.isAprobarEnProceso = false;
  }

  onClickDictaminar = () => {
    this.setState({ newCom: '', isModoDictaminar: true });
  };

  getCmdDictaminar = sol => {
    if (sol.idEstatus === 'aprob') {
      return (
        <Button
          className="cmdSol cmdDictaminar gray btn-small "
          title="Aprobar"
        >
          <i className="fas fa-check-double" />
          Dictaminar
        </Button>
      );
    }

    return (
      <Button
        className="cmdSol cmdDictaminar blue btn-small "
        title="Aprobar / Rechazar"
        onClick={event => this.onClickDictaminar()}
      >
        <i className="fas fa-check-double mrBTN" />
        Dictaminar
      </Button>
    );
  };

  onTextoChange = texto => {
    this.setState({
      newCom: texto
    });
  };

  onCancelDictaminar = () => {
    if (this.state.isAprobarEnProceso) {
      return;
    }

    this.setState({
      newCom: '',
      isModoDictaminar: false
    });
  };

  onAprobarSol = async () => {
    const idSol = this.props.sol._id;

    if (this.state.isAprobarEnProceso) {
      return;
    }

    let texto = this.state.newCom || '';

    texto = texto.trim();

    this.setState({
      isAprobarEnProceso: true
    });

    //comenzar la aprobación
    const respuesta = await DataService.Mc.aprobarSol(idSol, texto);

    if (!respuesta.success) {
      LibToast.alert(respuesta.msg);
      this.setState({
        isAprobarEnProceso: false
      });
      return;
    }

    LibMsgWSService.sol.cambioEstatus(this.props.sol, 'aprob', texto);

    const msg = `Solicitud de ${this.props.sol.nombreCliente} APROBADA`;
    LibMsgFlash.setMsgSuccess(msg, idSol);
    window.location = UrlPages.MIS_SOLICITUDES;
  };

  onRechazarSol = async () => {
    const idSol = this.props.sol._id;

    if (this.state.isAprobarEnProceso) {
      return;
    }

    let texto = this.state.newCom || '';

    texto = texto.trim();

    if (texto === '') {
      LibToast.alert(
        'Se requiere indicar como comentario el motivo del rechazo de la solicitud'
      );
      return;
    }

    this.setState({
      isAprobarEnProceso: true
    });

    //comenzar la aprobación
    const respuesta = await DataService.Mc.rechazarSol(idSol, texto);

    if (!respuesta.success) {
      LibToast.alert(respuesta.msg);
      this.setState({
        isAprobarEnProceso: false
      });
      return;
    }

    LibMsgWSService.sol.cambioEstatus(this.props.sol, 'rechazada', texto);

    const msg = `Solicitud de ${this.props.sol.nombreCliente} rechazada`;
    LibMsgFlash.setMsgSuccess(msg, idSol);
    window.location = UrlPages.MIS_SOLICITUDES;
  };

  onAddComentario = async () => {
    const idSol = this.props.sol._id;

    if (this.state.isAprobarEnProceso) {
      return;
    }

    let texto = this.state.newCom || '';

    texto = texto.trim();

    if (texto === '') {
      LibToast.alert('Falta indicar el comentario');
      return;
    }

    //comenzar la aprobación
    const respuesta = await DataService.Mc.comentario(idSol, texto);

    if (!respuesta.success) {
      LibToast.alert(respuesta.msg);
      this.setState({
        isAprobarEnProceso: false
      });
      return;
    }

    LibToast.success('Comentario registrado');

    const newCom = respuesta.data.comentario;
    this.props.onCrudSol.addComentario(idSol, newCom);
    LibMsgWSService.sol.comentario(this.props.sol, newCom);

    const listaCom = [...this.state.listaCom, newCom];

    this.setState({
      isAprobarEnProceso: false,
      newCom: '',
      listaCom
    });
  };

  render() {
    const sol = this.props.sol;

    let cmdDictaminar = this.getCmdDictaminar(sol);

    if (this.state.isModoDictaminar) {
      cmdDictaminar = null;
    }

    //Los cometnarios se agregan como push, se debe invertir el orden
    const lista = this.state.listaCom
      .map((item, index) => {
        return <ComSol itemCom={item} key={index} />;
      })
      .reverse();

    let cmdAddCom = null;

    if (!this.state.isModoDictaminar) {
      cmdAddCom = (
        <Button
          className="blue cmdDictaminar btn-small"
          style={{ marginLeft: '6px' }}
          onClick={event => this.onAddComentario()}
        >
          <i className="fas fa-plus mrBTN" />
          Agregar Comentario
        </Button>
      );
    }

    let cmdAprobarRechazar = null;

    if (this.state.isModoDictaminar) {
      cmdAprobarRechazar = (
        <Fragment>
          <div className="divFlex">
            <Button
              className="btn btn-small green flexgrow"
              onClick={event => this.onAprobarSol()}
            >
              <i className="fas fa-check mrBTN" />
              Aprob
            </Button>
            <Button
              className="btn btn-small red flexgrow"
              onClick={event => this.onRechazarSol()}
            >
              <i className="fas fa-times mrBTN" />
              Rech
            </Button>
          </div>
        </Fragment>
      );
    }

    let cmdCancel = null;
    let infoDictaminar = null;
    if (this.state.isModoDictaminar) {
      cmdCancel = (
        <Button
          className="btn cmdSol cmdDictaminar btn-black btn-small "
          onClick={event => this.onCancelDictaminar()}
          style={{ width: '100%', marginBottom: '20px' }}
        >
          Cancelar
        </Button>
      );

      const listaInfo = sol.listaInfo ? sol.listaInfo : [];

      const listaDataInfo = listaInfo.map((item, index) => {
        return (
          <div className="wrapperInfoDictaminar" key={index}>
            <p className="labelDataInfo">{item.nombre}</p>
            <p className="labelDataValor">{item.valor}</p>
          </div>
        );
      });
      infoDictaminar = (
        <Fragment>
          <div className="wrapperInfoDictaminar" key="nombrecliente">
            <p className="labelDataInfo">Nombre del Cliente</p>
            <p className="labelDataValor">{sol.nombreCliente}</p>
          </div>
          {listaDataInfo}
        </Fragment>
      );
    }

    let labEnProceso = this.state.isAprobarEnProceso ? (
      <Fragment>
        <i className="fas fa-cog fa-spin" /> En Proceso
      </Fragment>
    ) : null;

    return (
      <div className="colDictamen">
        {cmdDictaminar}
        {cmdCancel}
        <div className="panNewCom">
          {infoDictaminar}
          <label style={{ marginLeft: '10px' }}>Nuevo comentario:</label>
          <textarea
            className="txtNewCom"
            style={{ width: '226px', height: '60px', marginLeft: '8px' }}
            onChange={event => {
              this.onTextoChange(event.target.value);
            }}
            value={this.state.newCom}
          />
          {cmdAddCom}
          {cmdAprobarRechazar}
          <p>{labEnProceso}</p>
        </div>
        {lista}
      </div>
    );
  }
}

export default SolDictamen;
