import React from 'react';

import { Button, Modal, Switch, TextInput } from 'react-materialize';
import LibValidacion from '../../../lib/LibValidacion';
import LibToast from '../../../lib/LibToast';

class DocInfoForm extends React.Component {
  constructor(props, context) {
    super(props, context);

    this.state = {
      listaInfo: props.lista ? props.lista : [],
      isShowModal: false,
      isModalNew: false,
      infoEdit: { nombre: '', posicion: 1, tipo: '', meta: '' },
      infoDelete: { _id: null }
    };
  }

  componentWillReceiveProps(newProps) {
    const oldProps = this.props;
    if (JSON.stringify(oldProps.listaInfo) !== JSON.stringify(newProps.lista)) {
      this.setState({ listaInfo: newProps.lista });
    }
  }

  onEdit = (event, item) => {
    event.preventDefault();

    this.setState({
      infoEdit: { ...item },
      isShowModal: true,
      isModalNew: false
    });
    const modal = window.M.Modal.getInstance(
      document.getElementById('modalInfoDoc')
    );
    modal.open();
  };

  onChangeTextDoc = (nuevoValor, propiedad) => {
    const infoEdit = { ...this.state.infoEdit };
    infoEdit[propiedad] = nuevoValor;
    this.setState({ infoEdit });
  };

  onCreate = event => {
    event.preventDefault();

    this.setState({
      infoEdit: {
        _id: null,
        nombre: '',
        tipo: '',
        meta: '',
        posicion: 1,
        isNoRequerido: false
      },
      isShowModal: true,
      isModalNew: true
    });

    const modal = window.M.Modal.getInstance(
      document.getElementById('modalInfoDoc')
    );
    modal.open();
  };

  onChangeBoolAsignacion = nuevoValorBool => {
    const infoEdit = { ...this.state.infoEdit };
    infoEdit.isNoRequerido = nuevoValorBool;
    this.setState({ infoEdit });
  };

  render() {
    const { listaInfo } = this.state;

    const tituloForm = this.state.isModalNew ? 'Agregar' : 'Editar';

    const listaTR = listaInfo.map((item, index) => {
      const iconNoRequerido = item.isNoRequerido ? (
        <span>
          <i className="fas fa-exclamation" /> Opcional
        </span>
      ) : null;

      return (
        <tr key={index}>
          <td>
            <Button
              className="waves-effect waves-light btn-small"
              onClick={event => {
                return this.onEdit(event, item);
              }}
              title="Editar"
            >
              <i className="fas fa-edit" />
            </Button>
          </td>
          <td>{item.posicion}</td>
          <td>{item.nombre}</td>
          <td>{iconNoRequerido}</td>
          <td>
            <Button
              className="waves-effect red waves-light btn-small"
              onClick={event => {
                return this.onShowModalDelete(event, item);
              }}
              title="Eliminar"
            >
              <i className="fas fa-trash" />
            </Button>
          </td>
        </tr>
      );
    });

    const { infoEdit, infoDelete } = this.state;

    return (
      <div>
        <div className="divFlex">
          <Button
            className="waves-effect waves-light btn-small mrBTN"
            onClick={event => {
              this.onCreate(event);
            }}
          >
            <i className="fas fa-plus" title="Agregar Dato de la Solicitud" />
          </Button>
          <p style={{ marginTop: 5 }}>Datos Adicionales</p>
        </div>
        <table className="table">
          <thead>
            <tr>
              <th className="tdcmd" />
              <th className="tdcmd">Posición</th>
              <th>Dato</th>
              <th style={{ width: 120 }}>Es Opcional</th>
              <th className="tdcmd" />
            </tr>
          </thead>
          <tbody>{listaTR}</tbody>
        </table>

        <Modal id="modalInfoDoc">
          <h5 className="tituloTabla">
            {tituloForm} Dato Adicional {infoEdit._id}
          </h5>

          <TextInput
            value={infoEdit.nombre}
            label="Dato"
            onChange={event =>
              this.onChangeTextDoc(event.target.value, 'nombre')
            }
          />

          <TextInput
            value={infoEdit.posicion.toString()}
            label="Posicion"
            onChange={event =>
              this.onChangeTextDoc(event.target.value, 'posicion')
            }
          />

          <div>
            <label>Es Opcional</label>
            <div>
              <Switch
                offLabel="No"
                id="chkInfoDocOpc"
                onLabel="Sí"
                checked={infoEdit.isNoRequerido}
                onChange={event =>
                  this.onChangeBoolAsignacion(event.target.checked)
                }
              />
            </div>
          </div>

          <div className="divFlex mt-20">
            <Button
              className="waves-effect waves-light btn-small mrBTNXL"
              onClick={this.onSubmitModal}
              title="Guardar"
            >
              <i className="fas fa-upload mrBTN" />
              Guardar Dato
            </Button>

            <Button
              className="waves-effect waves-light btn-small black"
              onClick={event => {
                return this.onCancelModal(event);
              }}
              title="Cancelar"
            >
              <i className="fas fa-times mrBTN" />
              Cancelar
            </Button>
          </div>
        </Modal>

        <Modal id="modalDeleteInfo">
          <h6 className="tituloDelete">
            <i className="fas fa-trash mrBTN" /> Eliminar Dato
          </h6>
          <h3>{infoDelete.nombre}</h3>
          <div>
            <Button
              className="red small"
              onClick={event => this.onExeDelete(event)}
            >
              <i className="fas fa-trash mrBTN" /> Eliminar
            </Button>
          </div>
        </Modal>
      </div>
    );
  }

  /*seccion moda*/

  onCancelModal = event => {
    event.preventDefault();
    const modal = window.M.Modal.getInstance(
      document.getElementById('modalInfoDoc')
    );
    modal.close();
  };

  onSubmitModal = event => {
    event.preventDefault();

    const idEdit = this.state.infoEdit._id;
    let listaInfo = [...this.state.listaInfo];

    const info = this.state.infoEdit;

    //validar el infoEdit

    const isValid = LibValidacion.objectTieneProp(info, ['nombre', 'posicion']);

    if (!isValid) {
      LibToast.alert('Se requieren completar todos los campos');
      return;
    }

    info.posicion = parseInt(info.posicion);

    if (idEdit === null) {
      //agregar el documento

      info._id = 'new';
      listaInfo.push(info);
    } else {
      //actualizar un doc existnte
      listaInfo = listaInfo.map(item => {
        if (item._id !== info._id) {
          return item;
        }

        item.nombre = info.nombre;
        item.posicion = info.posicion;
        item.isNoRequerido = info.isNoRequerido;

        return item;
      });
    }

    listaInfo = listaInfo.sort((a, b) => {
      if (a.posicion === b.posicion) {
        return 0;
      }
      if (a.posicion > b.posicion) {
        return 1;
      }
      return -1;
    });

    this.setState({ listaInfo });
    const modal = window.M.Modal.getInstance(
      document.getElementById('modalInfoDoc')
    );
    modal.close();

    this.props.onChangeModel(listaInfo);
  };

  onShowModalDelete = (event, row) => {
    event.preventDefault();

    this.setState({ docDelete: row });

    const modal = window.M.Modal.getInstance(
      document.getElementById('modalDeleteInfo')
    );
    modal.open();
  };

  onExeDelete = event => {
    event.preventDefault();

    let lista = [...this.state.listaInfo];

    const listaInfo = lista.filter(item => {
      return !(
        item._id === this.state.docDelete._id &&
        item.nombre === this.state.docDelete.nombre
      );
    });
    this.setState({ listaInfo });

    const modal = window.M.Modal.getInstance(
      document.getElementById('modalDeleteInfo')
    );
    modal.close();

    this.props.onChangeModel(listaInfo);
  };
}

export default DocInfoForm;
