import React, { Fragment } from 'react';
import InfoSolEdit from './InfoSolEdit';
import { Button } from 'react-materialize';

class SolUpdateModalData extends React.Component {
  constructor(props, context) {
    super(props, context);

    const sol = props.sol;

    let listaData = [
      {
        propiedad: 'nombreCliente',
        valor: sol.nombreCliente,
        label: 'Nombre del Cliente',
        metaPropiedad: ''
      }
    ];

    if (sol.listaInfo) {
      const lista = sol.listaInfo.map(item => {
        return {
          propiedad: item.nombre,
          label: item.nombre,
          valor: item.valor,
          metaPropiedad: 'listaInfo'
        };
      });

      listaData = [listaData[0], ...lista];
    }

    this.state = { listaData };

    this.onChangePropiedad.bind(this);
    this.onExeUpdate.bind(this);
  }

  onChangePropiedad(nuevoValor, propiedad, metaPropiedad) {
    const listaData = this.state.listaData.map(item => {
      if (
        item.propiedad === propiedad &&
        item.metaPropiedad === metaPropiedad
      ) {
        item.valor = nuevoValor;
      }

      return item;
    });

    this.setState({ listaData });
  }

  onExeUpdate() {
    //La actualziacion se ejecuta desde el solPage
    this.props.onExeUpdate(this.state.listaData);
  }

  render() {
    const sol = this.props.sol;

    const listaElem = this.state.listaData.map((item, index) => {
      return (
        <InfoSolEdit
          valor={item.valor}
          label={item.label}
          propiedad={item.propiedad}
          metaPropiedad={item.metaPropiedad}
          onChangePropiedad={(a, b, c) => this.onChangePropiedad(a, b, c)}
          key={index}
        />
      );
    });

    return (
      <Fragment>
        <h5>
          <i className="fas fa-edit mrBTN" />
          Actualizar Solicitud
          <span style={{ float: 'right', color: 'black', fontSize: 13 }}>
            {sol.estatus} {sol.fregDMY}
          </span>
        </h5>
        <div className="contenidoModal">{listaElem}</div>
        <div>
          <Button className="small" onClick={event => this.onExeUpdate()}>
            Actualizar
          </Button>
        </div>
      </Fragment>
    );
  }
}

export default SolUpdateModalData;
