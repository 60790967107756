import ReqJson from '../../../lib/ReqJson';
import configApi from './configApi';
import AuthService from '../../authService/AuthService';

const factoryH = () => {
  const headerApi = {};
  headerApi[configApi.nombreHeaderAuthorization] = AuthService.getToken();
  return headerApi;
};

const ApiReqJson = new ReqJson(factoryH);

export default ApiReqJson;
