import React from 'react';

import {
  BrowserRouter as Router,
  Redirect,
  Route,
  Switch
} from 'react-router-dom';
import ObserverWindowH from '../lib/ObserverWindowH';

import AuthService from '../services/authService/AuthService';
import UrlPages from '../pages/UrlPages';

import LoginPage from '../pages/login/LoginPage';

import DashboardPage from '../pages/dashboard/DashboardPage';
import CuentaPage from '../pages/cuenta/CuentaPage';

import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

import LogoutPage from '../pages/login/LogoutPage';
import MisSolicitudesPageHOC from '../pages/solicitudes/MisSolicitudesPageHOC';

import MsgPageHOC from '../pages/msg/MsgPageHOC';
import SolicitudTipoPageHOC from '../pages/adm/SolicitudTipo/SolicitudTipoPageHOC';
import SolicitudEstatusPageHOC from '../pages/adm/SolicitudEstatus/SolicitudEstatusPageHOC';

import PermisoVendedorPageHOC from '../pages/adm/PermisoVendedor/PermisoVendedorPageHOC';
import PermisoVendedorCrudPage from '../pages/adm/PermisoVendedor/PermisoVendedorCrudPage';

import PermisoMCPageHOC from '../pages/adm/PermisoMC/PermisoMCPageHOC';
import PermisoMCCrudPage from '../pages/adm/PermisoMC/PermisoMCCrudPage';

import AreaVentasPageHOC from '../pages/adm/AreaVentas/AreaVentasPageHOC';
import AreaVentasCrudPage from '../pages/adm/AreaVentas/AreaVentasCrudPage';

import ContextProvider from './ContextProvider';
import SolPageHOC from '../pages/sol/SolPageHOC';
import UsuariosPageHOC from '../pages/adm/Usuarios/UsuariosPageHOC';
import LibMsgFlash from '../services/msgFlash/LibMsgFlash';
import LibToast from '../lib/LibToast';
import AllSolicitudesPageHOC from '../pages/solicitudes/AllSolicitudesPageHOC';
import UsuariosCrudPage from '../pages/adm/Usuarios/UsuariosCrudPage';
import SolicitudTipoCrudPage from '../pages/adm/SolicitudTipo/SolicitudTipoCrudPage';

class App extends React.Component {
  constructor(props) {
    super(props);
    const isAutenticado = AuthService.getIsAuthenticated();

    //cargar datos adicionels

    this.state = {
      isAutenticado,
      listaTipoSolicitud: []
    };
  }

  async componentDidMount() {
    window.addEventListener('resize', () => {
      const h = window.innerHeight;
      ObserverWindowH.onWindowsRezise.raise(h);
    });

    //Obtener msg flash
    const dataFlash = LibMsgFlash.getMsg();
    LibToast.fromDataFlash(dataFlash);
  }

  onLogIn = () => {
    window.location = UrlPages.MIS_SOLICITUDES;
  };

  render() {
    return (
      <ContextProvider>
        <div id="App">
          <Router>
            <Switch>
              {/* ---------------------------------- */}
              <Route
                path={UrlPages.LOGIN}
                exact
                component={() => <LoginPage onLogIn={() => this.onLogIn()} />}
              />
              <Route
                path={UrlPages.LOGOUT}
                exact
                component={() => <LogoutPage />}
              />
              {/* ---------------------------------- */}

              <Route path={UrlPages.DASHBOARD} component={DashboardPage} />

              <Route path={UrlPages.VER_SOL} component={SolPageHOC} />

              <Route
                path={UrlPages.MIS_SOLICITUDES}
                component={MisSolicitudesPageHOC}
              />
              <Route
                path={UrlPages.ALL_SOLICITUDES}
                component={AllSolicitudesPageHOC}
              />
              <Route path={UrlPages.MSG_INDEX} component={MsgPageHOC} />

              <Route
                path={UrlPages.ADM_SOLTIPO_INDEX}
                component={SolicitudTipoPageHOC}
              />
              <Route
                path={UrlPages.ADM_SOLTIPO_CRUD + '/:ope/:id'}
                component={SolicitudTipoCrudPage}
              />

              <Route
                path={UrlPages.ADM_SOLESTATUS_INDEX}
                component={SolicitudEstatusPageHOC}
              />
              <Route
                path={UrlPages.ADM_USUARIO_INDEX}
                component={UsuariosPageHOC}
              />
              <Route
                path={UrlPages.ADM_USUARIO_CRUD + '/:ope/:id'}
                component={UsuariosCrudPage}
              />

              <Route
                path={UrlPages.ADM_PERMISO_VENDEDOR_INDEX}
                component={PermisoVendedorPageHOC}
              />

              <Route
                path={UrlPages.ADM_PERMISO_VENDEDOR_CRUD + '/:ope/:id'}
                component={PermisoVendedorCrudPage}
              />

              <Route
                path={UrlPages.ADM_PERMISO_MC_INDEX}
                component={PermisoMCPageHOC}
              />
              <Route
                path={UrlPages.ADM_PERMISO_MC_CRUD + '/:ope/:id'}
                component={PermisoMCCrudPage}
              />

              <Route
                path={UrlPages.ADM_AREAVENTAS_INDEX}
                component={AreaVentasPageHOC}
              />
              <Route
                path={UrlPages.ADM_AREAVENTAS_CRUD + '/:ope/:id'}
                component={AreaVentasCrudPage}
              />

              <Route path={UrlPages.CUENTA} component={CuentaPage} />

              {/* ---------------------------------- */}

              <Redirect to="/" />
            </Switch>
          </Router>
          <ToastContainer />
        </div>
      </ContextProvider>
    );
  }
}

export default App;
