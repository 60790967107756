import { toast } from 'react-toastify';

const LibToast = {
  success(msg) {
    toast.success(msg, {
      position: toast.POSITION.TOP_RIGHT
    });
  },

  alert(msg, titulo = 'Error') {
    toast.error('Error ' + msg, {
      position: toast.POSITION.TOP_RIGHT
    });
  },
  info(msg) {
    toast.info(msg, {
      position: toast.POSITION.TOP_RIGHT
    });
  },
  fromDataFlash(dataFlash) {
    if (dataFlash === undefined || dataFlash === null || dataFlash === '') {
      return;
    }

    if (dataFlash.tipo === 'success') {
      this.success(dataFlash.msg);
    } else if (dataFlash.tipo === 'info') {
      this.info(dataFlash.msg);
    } else {
      this.error(dataFlash.msg);
    }
  }
};

export default LibToast;
