import React from 'react';
import { Button } from 'react-materialize';
import UrlPages from '../../UrlPages';

function SolicitudTipoTr(props) {
  const { row, idLast } = props;

  const onEdit = id => {
    window.location = `${UrlPages.ADM_SOLTIPO_CRUD}/update/${id}`;
  };

  const TrCss = idLast === row._id ? 'lastModel' : '';

  const isActivo = row.isActivo ? <i className="fas fa-thumb-up" /> : null;

  const listaLidDoc = row.listaDocTipo.map(item => {
    const textoOpcional = item.isNoRequerido ? <span>(Opcional)</span> : null;

    return (
      <li key={item._id}>
        {item.docTipo} {textoOpcional}
      </li>
    );
  });

  const listaLiInfo = row.listaInfo.map((item, index) => {
    const textoOpcional = item.isNoRequerido ? <span>(Opcional)</span> : null;

    return (
      <li key={index}>
        {item.nombre} {textoOpcional}
      </li>
    );
  });

  return (
    <tr className={TrCss}>
      <td>
        <Button
          className="waves-effect waves-light btn-small blue"
          onClick={event => onEdit(row._id)}
        >
          <i className="fas fa-edit" />
        </Button>
      </td>
      <td>
        <h4 className="tituloRow">{row.tipo}</h4>
      </td>
      <td>
        <ol>{listaLidDoc}</ol>
      </td>
      <td>
        <ol>{listaLiInfo}</ol>
      </td>
      <td>{isActivo}</td>
      <td>{row.comentario}</td>
    </tr>
  );
}

export default SolicitudTipoTr;
