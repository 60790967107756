import React, { Fragment } from 'react';
import { Button } from 'react-materialize';
import UrlPages from '../../UrlPages';

function PermisoMCTr(props) {
  const { index, row, idLast } = props;

  const onEdit = id => {
    window.location = `${UrlPages.ADM_PERMISO_MC_CRUD}/update/${id}`;
  };

  const TrCss = idLast === row.idUsuario ? 'lastModel' : '';

  const iconIsPermiso = row.isPermisoMC ? <i className="fas fa-check" /> : null;

  let elemPermiso = null;

  if (row.isPermisoMC) {
    const liGestor = row.permisoMC.isGestor ? <li> Es Gestor</li> : null;
    const liAdmin = row.permisoMC.isAdmin ? (
      <li>
        Es Administrador <i className="fas fa-star" />
      </li>
    ) : null;

    let olAsignacion = <p className="textoLight">-Sin Areas Asignadas-</p>;
    if (row.permisoMC.listaAreas && row.permisoMC.listaAreas.length > 0) {
      const lista = row.permisoMC.listaAreas.map((item, index) => {
        return (
          <li title={item.idArea} key={index}>
            {item.area}
          </li>
        );
      });
      olAsignacion = <ol>{lista}</ol>;
    }

    elemPermiso = (
      <Fragment>
        <ul>
          {liGestor}
          {liAdmin}
        </ul>
        <h3 className="subtituloTD">
          <i className="fas fa-home" />
          Áreas Asignadas
        </h3>
        {olAsignacion}
      </Fragment>
    );
  }

  return (
    <tr key={index} className={TrCss}>
      <td>
        <Button
          className="waves-effect waves-light btn-small blue"
          onClick={event => onEdit(row._id)}
        >
          <i className="fas fa-edit" />
        </Button>
      </td>
      <td>
        <h4 className="tituloRow">{row.nombre}</h4>
      </td>
      <td>{row.email}</td>
      <td>{iconIsPermiso}</td>
      <td>{elemPermiso}</td>
    </tr>
  );
}

export default PermisoMCTr;
