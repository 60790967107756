import React, { Fragment } from 'react';

function SolDeleteModalData({ sol }) {
  return (
    <Fragment>
      <h5 className="tituloDelete">
        <i className="fas fa-trash mrBTN" />
        Eliminar Solicitud
        <span style={{ float: 'right', color: 'black' }}>
          {sol.estatus} /{sol.fregDMY}
        </span>
      </h5>
      <div className="contenidoModal">
        <h4> {sol.nombreCliente} </h4>
        <h5>{sol.tipo}</h5>
        <h6 title="Vendedor">
          <i className="fa fa-user mrBTN" />
          {sol.nombreVendedor} /{sol.area} /
        </h6>
      </div>
    </Fragment>
  );
}

export default SolDeleteModalData;
