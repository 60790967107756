import React from 'react';
import HOCProtectedPage from '../../HOCProtectedPage';
import TopBar from '../../../pageComponents/topbar/TopBar';

import DataService from '../../../services/dataService/dataApi/DataApi';
import LibToast from '../../../lib/LibToast';
import { Button, Modal, Textarea, TextInput } from 'react-materialize';
import UrlPages from '../../UrlPages';
import LibValidacion from '../../../lib/LibValidacion';
import LibMsgFlash from '../../../services/msgFlash/LibMsgFlash';
import DetalleUXA from './DetalleUXA';

const campo = {
  AreaTipo: 'areaTipo',
  Area: 'area',
  Prioridad: 'prioridad',
  Comentario: 'comentario'
};

class AreaVentasCrudPage extends React.Component {
  constructor(props, context) {
    super(props, context);

    const id = props.match.params.id || null;
    const ope = props.match.params.ope || null;

    this.state = {
      model: { listaUXArea: [], prioridad: 2 },
      id,
      ope,
      isShowModalDelete: false
    };

    this.isEnProceso = false;
    this.onSubmit.bind(this);
  }

  async componentDidMount() {
    const { id, ope } = this.state;

    if (ope === 'update') {
      //solicitar los datos del registro
      const respuesta = await DataService.Mc.AreaVentas.Detalle(id);

      if (!respuesta.success) {
        LibToast.alert(respuesta.msg);
        return;
      }

      const model = respuesta.data.area;

      model.prioridad = parseInt(model.prioridad);

      LibToast.success('Datos recibidos - ' + model.area);
      this.setState({ model });
    }
  }

  onChangeText = (nuevoValor, propiedad) => {
    const model = { ...this.state.model };
    model[propiedad] = nuevoValor;
    this.setState({ model });
  };

  onCancel() {
    const url = UrlPages.ADM_AREAVENTAS_INDEX;
    const model = this.state.model;

    LibMsgFlash.setMsgInfo(`${model.nick}`, model._id);

    this.props.history.push(url);
  }

  onShowModalDelete = () => {
    const modal = window.M.Modal.getInstance(
      document.getElementById('modalDeleteArea')
    );
    modal.open();
  };

  onSubmit = async event => {
    if (this.isEnProceso) {
      event.preventDefault();
      return;
    }

    //validar -----------------------------
    const model = this.state.model;
    const listaP = ['area', 'areaTipo', 'prioridad'];
    const isValid = LibValidacion.objectTieneProp(model, listaP);
    if (isValid !== true) {
      LibToast.alert(isValid);
      return;
    }

    this.isEnProceso = true;

    //hacer la actualizacion --------------
    const id = model._id || null;

    let respuesta = null;

    if (id) {
      respuesta = await DataService.Mc.AreaVentas.Update(id, model);
    } else {
      respuesta = await DataService.Mc.AreaVentas.Create(model);
    }

    if (!respuesta.success) {
      this.isEnProceso = false;
      LibToast.alert(respuesta.msg);
      return;
    }

    let tagMsg = 'Se actualizó';

    if (model._id === undefined) {
      //caso de ser nuevo refgistro
      model._id = respuesta.data.area._id;
      tagMsg = 'Se creó';
    }

    LibMsgFlash.setMsgInfo(`${tagMsg} ${model.area}`, model._id);
    const url = UrlPages.ADM_AREAVENTAS_INDEX;
    this.props.history.push(url);

    event.preventDefault();
  };

  render() {
    if (this.state.ope === null) {
      return null;
    }

    const model = this.state.model;
    const label = model._id ? 'Actualizar Área' : 'Nueva Área';

    let cmdDelete = null;

    if (model._id) {
      cmdDelete = (
        <Button
          className="red floatR mt-20 mrBTN"
          onClick={event => this.onShowModalDelete()}
        >
          <i className="fas fa-trash mrBTN" />
          Eliminar
        </Button>
      );
    }

    return (
      <HOCProtectedPage>
        <TopBar />

        {cmdDelete}

        <div className="row">
          <div className="col s6">
            <form onSubmit={event => this.onSubmit(event)} className=" panForm">
              <h5 className="tituloTabla mb20">{label}</h5>

              <div className="row ">
                <TextInput
                  value={model.area}
                  s="12"
                  label="Area"
                  onChange={event =>
                    this.onChangeText(event.target.value, campo.Area)
                  }
                />
              </div>

              <div className="row">
                <TextInput
                  value={model.areaTipo}
                  s="6"
                  label="Tipo de Area"
                  onChange={event =>
                    this.onChangeText(event.target.value, campo.AreaTipo)
                  }
                />

                <TextInput
                  label="Prioridad"
                  type="text"
                  value={model.prioridad.toString()}
                  s="6"
                  onChange={event =>
                    this.onChangeText(event.target.value, campo.Prioridad)
                  }
                />
              </div>

              <div className="row">
                <Textarea
                  label="Comentario"
                  value={model.comentario}
                  s="12"
                  onChange={event =>
                    this.onChangeText(event.target.value, campo.Comentario)
                  }
                />
              </div>

              <div className="divFlex">
                <Button
                  className="waves-effect waves-light btn-small mrBTNXL"
                  onClick={this.onSubmit}
                  title="Guardar"
                >
                  <i className="fas fa-upload mrBTN" />
                  Guardar
                </Button>

                <Button
                  className="waves-effect waves-light btn-small black"
                  onClick={event => {
                    return this.onCancel();
                  }}
                  title="Cancelar"
                >
                  <i className="fas fa-times mrBTN" />
                  Cancelar
                </Button>
              </div>
            </form>
          </div>
          <div className="col s6">
            <DetalleUXA
              listaUXArea={model.listaUXArea}
              tituloU="Vendedores Asociados"
              tipoUsuario="v"
            />
            <DetalleUXA
              listaUXArea={model.listaUXArea}
              tituloU="Mesa de Control asignada"
              tipoUsuario="mc"
            />
          </div>
        </div>

        <Modal id="modalDeleteArea">
          <h6 className="tituloDelete">
            <i className="fas fa-trash mrBTN" /> Eliminar Área
          </h6>
          <h3>{model.area}</h3>
          <div>
            <Button className="red small" onClick={event => this.onExeDelete()}>
              <i className="fas fa-trash mrBTN" /> Eliminar Área
            </Button>
          </div>
        </Modal>
      </HOCProtectedPage>
    );
  }

  onExeDelete = async () => {
    //ejecutar la eliminarcion
    if (this.isEnProceso) {
      return;
    }

    this.isEnProceso = true;

    const id = this.state.model._id;
    const respuesta = await DataService.Mc.AreaVentas.Delete(id);

    if (!respuesta.success) {
      this.isEnProceso = false;
      LibToast.alert(respuesta.msg);
      return;
    }

    LibMsgFlash.setMsgInfo('Se eliminó ' + this.state.model.area, id);

    const url = UrlPages.ADM_AREAVENTAS_INDEX;
    this.props.history.push(url);
  };
}

export default AreaVentasCrudPage;
