import React, { Fragment } from 'react';
import HOCProtectedPage from '../../HOCProtectedPage';
import TopBar from '../../../pageComponents/topbar/TopBar';
import AppContext from '../../../app/AppContext';
import SolicitudEstatusPage from './SolicitudEstatusPage';

function SolicitudEstatusPageHOC() {
  return (
    <HOCProtectedPage>
      <AppContext.Consumer>
        {context => (
          <Fragment>
            <TopBar />
            <SolicitudEstatusPage listaSolEstatus={context.listaSolEstatus} />
          </Fragment>
        )}
      </AppContext.Consumer>
    </HOCProtectedPage>
  );
}

export default SolicitudEstatusPageHOC;
