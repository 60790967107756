/*Este es una calse de backendssl/EstatusSol */
import React from 'react';

const EstatusSol = {
  ID_EN_ELABORACION: 'elab',
  ID_ESPERANDO_EVALUACION: 'espera',
  ID_APROBADA: 'aprob',
  ID_RECHAZADA: 'rechazada'
};

const dicCSS = {};
dicCSS[EstatusSol.ID_EN_ELABORACION] = 'green';
dicCSS[EstatusSol.ID_ESPERANDO_EVALUACION] = 'blue';
dicCSS[EstatusSol.ID_APROBADA] = 'green';
dicCSS[EstatusSol.ID_RECHAZADA] = 'red';

const dicLabel = {};
dicLabel[EstatusSol.ID_EN_ELABORACION] = 'En Elaboración';
dicLabel[EstatusSol.ID_ESPERANDO_EVALUACION] = 'Esperando Evaluación';
dicLabel[EstatusSol.ID_APROBADA] = 'Aprobada';
dicLabel[EstatusSol.ID_RECHAZADA] = 'Rechazada';

EstatusSol.dicCSS = dicCSS;
EstatusSol.dicLabel = dicLabel;

EstatusSol.getLabelFromEstatus = idEstatus => {
  const css = 'badge estatussol ' + idEstatus;
  const label = dicLabel[idEstatus];

  return <span className={css}>{label}</span>;
};

export default EstatusSol;
