import React from 'react';
import HOCProtectedPage from '../../HOCProtectedPage';
import TopBar from '../../../pageComponents/topbar/TopBar';

import DataService from '../../../services/dataService/dataApi/DataApi';
import LibToast from '../../../lib/LibToast';
import { Button, Modal, Switch } from 'react-materialize';
import UrlPages from '../../UrlPages';
import LibValidacion from '../../../lib/LibValidacion';
import LibMsgFlash from '../../../services/msgFlash/LibMsgFlash';

class PermisoMCCrudPage extends React.Component {
  constructor(props, context) {
    super(props, context);

    const id = props.match.params.id || null;
    const ope = props.match.params.ope || null;

    this.state = {
      model: {},
      listaAreas: [],
      id,
      ope
    };

    this.isEnProceso = false;
    this.onSubmit.bind(this);
  }

  async componentDidMount() {
    const { id, ope } = this.state;

    if (ope) {
      //solicitar los datos del registro

      const respuesta = await DataService.Mc.PermisoMC.FromUsuario(id);

      if (!respuesta.success) {
        LibToast.alert(respuesta.msg);
        return;
      }

      const model = respuesta.data.permisoMC;
      const listaAreas = respuesta.data.listaAreas;

      //actualizar con los valores de asignacion
      const listaIDArea = model.listaAreas.map(item => {
        return item.idArea;
      });

      //agregar neuva propiedad
      listaAreas.forEach(item => {
        item.isSelected = listaIDArea.includes(item._id);
      });

      this.setState({ model, listaAreas });

      LibToast.success('Datos recibidos  - ' + model.usuario);
    }
  }

  onChangeBool = (nuevoValorBool, propiedad) => {
    const model = { ...this.state.model };
    model[propiedad] = nuevoValorBool;

    this.setState({ model });
  };

  onChangeBoolAsignacion = (nuevoValorBool, idArea) => {
    const listaAreas = [...this.state.listaAreas];

    listaAreas.forEach(item => {
      if (item._id === idArea) {
        item.isSelected = nuevoValorBool;
      }
    });

    this.setState({ listaAreas });

    console.log('listaAreas', listaAreas);
  };

  onCancel() {
    const url = UrlPages.ADM_PERMISO_MC_INDEX;
    this.props.history.push(url);
  }

  onShowModalDelete = () => {
    const modal = window.M.Modal.getInstance(
      document.getElementById('modalDeletePermisoMC')
    );
    modal.open();
  };

  onSubmit = async event => {
    event.preventDefault();

    if (this.isEnProceso) {
      return;
    }

    //validar -----------------------------
    const model = this.state.model;
    const listaP = ['isAdmin', 'isGestor'];
    const isValid = LibValidacion.objectTieneProp(model, listaP);
    if (isValid !== true) {
      LibToast.alert(isValid);
      return;
    }

    this.isEnProceso = true;

    //hacer la actualizacion --------------
    const id = model._id || null;

    //agregar las areas asignadas
    let listaAreasAsignadas = [];
    const listaAreas = this.state.listaAreas;

    if (model.isGestor || model.isAdmin) {
      listaAreasAsignadas = listaAreas
        .filter(item => {
          return item.isSelected;
        })
        .map(item => {
          return {
            idArea: item._id,
            area: item.area,
            idUsuario: model.idUsuario,
            usuario: model.usuario
          };
        });
    }

    model.listaAreas = listaAreasAsignadas;

    let respuesta = null;

    if (id) {
      respuesta = await DataService.Mc.PermisoMC.Update(id, model);
    } else {
      respuesta = await DataService.Mc.PermisoMC.Create(model);
    }

    if (!respuesta.success) {
      this.isEnProceso = false;
      LibToast.alert(respuesta.msg);
      return;
    }

    let tagMsg = 'Se actualizó';

    if (model._id === undefined) {
      tagMsg = 'Se creó';
    }

    LibMsgFlash.setMsgInfo(`${tagMsg} ${model.usuario}`, model.idUsuario);
    const url = UrlPages.ADM_PERMISO_MC_INDEX;
    this.props.history.push(url);
  };

  render() {
    const { model, listaAreas } = this.state;

    if (this.state.ope !== null && model.idUsuario === undefined) {
      return null;
    }

    let cmdDelete = null;

    if (model._id) {
      cmdDelete = (
        <Button
          className="red floatR mt-20 mrBTN"
          onClick={event => this.onShowModalDelete()}
        >
          <i className="fas fa-trash mrBTN" />
          Eliminar
        </Button>
      );
    }

    const listaTrAreas = listaAreas.map((item, index) => {
      const isAsignado = item.isSelected;
      const idTag = `idSwitchArea${index}`;
      return (
        <tr key={index + 1}>
          <th>{item.area}</th>
          <td>
            <Switch
              offLabel="No"
              id={idTag}
              onLabel="Sí"
              checked={isAsignado}
              onChange={event =>
                this.onChangeBoolAsignacion(event.target.checked, item._id)
              }
            />
          </td>
        </tr>
      );
    });

    let tablaAreas = null;

    if (model.isGestor) {
      tablaAreas = (
        <table className="tableForm">
          <tbody>
            <tr>
              <th colSpan="2">Asignar Áreas de Ventas</th>
            </tr>
            {listaTrAreas}
          </tbody>
        </table>
      );
    }

    return (
      <HOCProtectedPage>
        <TopBar />

        {cmdDelete}

        <div className="panForm">
          <form onSubmit={event => this.onSubmit(event)} className="form">
            <h5 className="tituloTabla mb20">
              Permiso Mesa de Control de
              <span>{model.usuario}</span>
            </h5>

            <div className="row">
              <div className="col s3">
                <table className="tablaForm">
                  <tbody>
                    <tr>
                      <th>Es Gestor</th>
                      <td>
                        <Switch
                          id="switch-isGestor"
                          offLabel="No"
                          onLabel="Sí"
                          checked={model.isGestor}
                          onChange={event =>
                            this.onChangeBool(event.target.checked, 'isGestor')
                          }
                        />
                      </td>
                    </tr>
                    <tr>
                      <th>Es Administrador</th>
                      <td>
                        <Switch
                          id="switch-isAdmin"
                          offLabel="No"
                          onLabel="Sí"
                          checked={model.isAdmin}
                          onChange={event =>
                            this.onChangeBool(event.target.checked, 'isAdmin')
                          }
                        />
                      </td>
                    </tr>
                  </tbody>
                </table>
              </div>
              <div className="col s3">{tablaAreas}</div>
            </div>

            <div className="divFlex">
              <Button
                className="waves-effect waves-light btn-small mrBTNXL"
                onClick={this.onSubmit}
                title="Guardar"
              >
                <i className="fas fa-upload mrBTN" />
                Guardar
              </Button>

              <Button
                className="waves-effect waves-light btn-small black"
                onClick={event => {
                  return this.onCancel();
                }}
                title="Cancelar"
              >
                <i className="fas fa-times mrBTN" />
                Cancelar
              </Button>
            </div>
          </form>
        </div>

        <Modal id="modalDeletePermisoMC">
          <h6 className="tituloDelete">
            <i className="fas fa-trash mrBTN" /> Eliminar Permiso
          </h6>
          <h3>{model.usuario}</h3>
          <div>
            <Button className="red small" onClick={event => this.onExeDelete()}>
              <i className="fas fa-trash mrBTN" /> Eliminar Permiso Mesa de
              Control
            </Button>
          </div>
        </Modal>
      </HOCProtectedPage>
    );
  }

  onExeDelete = async () => {
    //ejecutar la eliminarcion
    if (this.isEnProceso) {
      return;
    }

    const model = this.state.model;

    this.isEnProceso = true;

    const id = model._id;
    const respuesta = await DataService.Mc.PermisoMC.Delete(id);

    if (!respuesta.success) {
      this.isEnProceso = false;
      LibToast.alert(respuesta.msg);
      return;
    }

    LibMsgFlash.setMsgInfo('Se eliminó ' + model.usuario, model.idUsuario);

    const url = UrlPages.ADM_PERMISO_MC_INDEX;
    this.props.history.push(url);
  };
}

export default PermisoMCCrudPage;
