import React from 'react';

import HOCProtectedPage from '../../HOCProtectedPage';
import TopBar from '../../../pageComponents/topbar/TopBar';
import PermisoVendedorPage from './PermisoVendedorPage';

function PermisoVendedorPageHOC() {
  return (
    <HOCProtectedPage>
      <TopBar />
      <PermisoVendedorPage />
    </HOCProtectedPage>
  );
}

export default PermisoVendedorPageHOC;
