import React, { Fragment, Component } from 'react';
import { Modal, Button } from 'react-materialize';
import DataService from '../../../services/dataService/DataService';
import LibToast from '../../../lib/LibToast';

import Table from '../../../pageComponents/table/Table';
import UsuarioTr from './UsuarioTr';
import LibMsgFlash from '../../../services/msgFlash/LibMsgFlash';

import UrlPages from '../../UrlPages';

class UsuariosPage extends Component {
  constructor(props, context) {
    super(props, context);
    this.state = {
      listaUsuarios: [],
      idLast: null
    };
  }

  async componentDidMount() {
    const respuesta = await DataService.Admin.Usuario.Index();

    if (!respuesta.success) {
      LibToast.alert(respuesta.msg);
      return;
    }

    LibToast.success('Datos recibidos Usuarios');

    this.setState({ listaUsuarios: respuesta.data.lista });

    const idFlash = LibMsgFlash.getIdLast();

    if (idFlash) {
      this.setState({ idLast: idFlash });

      const dataFlash = LibMsgFlash.getMsg();
      setTimeout(() => {
        LibToast.fromDataFlash(dataFlash);
      }, 300);
    }
  }

  render() {
    const { listaUsuarios, idLast } = this.state;

    const listaHead = [
      { label: '', claseCSS: 'tdcmd' },
      'Nombre',
      'email',
      'Super Admin',
      'Activo',
      'Intentos Login'
    ];

    const fn = (row, index, idLast) => {
      const key = 'k' + row._id;

      return <UsuarioTr index={index} row={row} key={key} idLast={idLast} />;
    };

    const onInsert = () => {
      window.location = UrlPages.ADM_USUARIO_CRUD + '/create/new';
    };

    const listaPropEnFiltro = ['nombre', 'nick', 'email'];

    const fnFiltro = (texto, item) => {
      let isConincidencia = false;
      listaPropEnFiltro.forEach(p => {
        if (item[p].toLowerCase().includes(texto)) {
          isConincidencia = true;
        }
      });
      return isConincidencia;
    };

    return (
      <Fragment>
        <Table
          titulo="Usuarios del Sistema"
          listaHead={listaHead}
          listaRows={listaUsuarios}
          fnRenderRow={fn}
          onInsert={onInsert}
          idLast={idLast}
          fnFiltro={fnFiltro}
        />
        <Modal id="modalRecPass">
          <h4 className="tituloDelete">
            <i className="fas fa-recicle" />
            Enviar correo recuperacion para el usuario
          </h4>

          <div>
            <Button className="red" onClick={event => {}}>
              Eliminar
            </Button>
          </div>
        </Modal>
      </Fragment>
    );
  }
}

export default UsuariosPage;
