import React, {Component} from 'react';
import DataService from "../../services/dataService/DataService";
import LibToast from "../../lib/LibToast";
import DataReqJson from "../../lib/DataReqJson";
import './ItemDocSolicitudTipo.css';
import MsgEnProceso from "../../pageComponents/MsgEnProceso/MsgEnProceso";


class ItemDocSolicitudMc extends Component {

  constructor(props, context) {
    super(props, context);
    this.state = {
      isEnProceso: false,
      listaImg: []
    }
  }


  async onChangeFile(event) {

    if(event.target.files.length===0){
      this.setState({
        isEnProceso: false
      });
      return ;
    }

    let file = event.target.files[0];

    const {sol} = this.props;

    const idDocTipo = "mc";

    const fileName = file.name;
    const indexPunto = fileName.indexOf('.');
    if (indexPunto < 0) {
      return;
    }


    this.setState({
      isEnProceso: true
    });

    //1.-solicitar el link para subir la imagen ********* */
    const ext = fileName.substring(indexPunto + 1);
    let respuesta = await DataService.Mc.getUploadS3Url(sol._id, idDocTipo, ext);

    if (!respuesta.success) {
      LibToast.alert(respuesta.msg);
      return;
    }


    const {urlS3, key} = respuesta.data;


    //2.-subir directamente a S3 la iamgen ************** */
    const respuestaS3 = await DataReqJson.requestPUTFile(urlS3, file, fileName);


    if (respuestaS3.toString() !== "") {
      LibToast.alert('Error de  S3 al subir imagen :' + respuestaS3);
      return;
    }

    /*3.- solicitar el url signed de la imagen ********* */

    const respuestaUrl = await DataService.Mc.getS3Url(key);

    if (!respuestaUrl.success) {
      LibToast.alert('Error de S3 al solicitar :' + respuestaUrl);
      return;
    }


    //obtener elfilename del archivo ********************
    // let p1=respuestaUrl.data.url.substr( respuestaUrl.data.url.indexOf("mc_"));
    // let fileName =p1.substr(0, p1.indexOf("?"));



    const imgNew = {
      idDocTipo: idDocTipo,
      url: respuestaUrl.data.url,
      file:fileName
    };

    sol.listaImg.unshift(imgNew);



    const listaImg = sol.listaImg.filter(item => {
      return item.idDocTipo === idDocTipo
    });

    this.setState({
      listaImg,
      isEnProceso: false
    });

    LibToast.success("Nueva Imagen Agregada");

    this.props.onImageAdd()
  }


  render() {

    const {documento, sol, index} = {...this.props};

    const listaImg = sol.listaImg
        .filter(item => {
          return item.idDocTipo === documento._id
        })
        .map((item, index) => {

          const fileName=item.file.toLowerCase();

          if(fileName.endsWith('pdf')){
            return (
                <iframe src={item.url} className="embedPDF"  key={index}/>
            )
          }else{
            return <div className="textCenter" key={index}><img src={item.url}
                             className="imgDocCaptured"
                             alt={item.url} /></div>
          }


        });


   const idDocTipo = documento._id;
    const controllId = `inputdoc-${idDocTipo}`;


    return (
        <div className="tipoDocSolicitud">
          <div className="wrapperCmdFoto ">
            <input type="file"
                   id={controllId}
                   accept="image/*,application/pdf"
                   capture="camera"
                   onChange={event => this.onChangeFile(event)}
            />


            <MsgEnProceso isEnProceso={this.state.isEnProceso}/>
            <div className="imgFiles">
              {listaImg}
            </div>
          </div>


        </div>
    );
  }
}

export default ItemDocSolicitudMc;
