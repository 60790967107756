import React, { Component } from 'react';
import LibToast from '../../lib/LibToast';
import LibValidacion from '../../lib/LibValidacion';

class FormLogin extends Component {
  constructor(props, context) {
    super(props, context);
    this.state = {
      email: props.email,
      password: '',
      isEnProceso: props.isEnProceso
    };
  }

  onTxtChange = (campo, valor) => {
    let dic = {};
    dic[campo] = valor;
    this.setState(dic);

    if (campo === 'email') {
      this.props.onUpdateEmail(valor);
    }
  };

  onSubmit = async event => {
    event.preventDefault();

    const email = this.state.email.trim();
    const pass = this.state.password.trim();

    let isValid = true;

    if (email === '') {
      LibToast.alert('Falta el Email');
      isValid = false;
    } else {
      //validar email
      isValid = LibValidacion.isEmailValid(email);
    }

    if (pass === '') {
      LibToast.alert('Falta el Password');
      isValid = false;
    }

    if (!isValid) {
      return;
    }

    await this.props.onSubmitForm(email, pass);
  };

  render() {
    const iconLoading = this.props.isEnProceso && (
      <div className="center-align">
        <i className="fa fa-cog fa-spin " />
        <span className="pl-1"> En Proceso</span>
      </div>
    );

    return (
      <form onSubmit={this.onSubmit} className="login-form">
        <div className="row margin">
          <div className="input-field col s12">
            <i className="fa fa-user prefix" />

            <input
              id="email"
              value={this.state.email}
              onChange={event => this.onTxtChange('email', event.target.value)}
              type="email"
            />
            <label htmlFor="email" className="center-align active">
              email
            </label>
          </div>
        </div>

        <div className="row margin">
          <div className="input-field col s12">
            <i className="fas fa-key prefix" />
            <input
              id="password"
              value={this.state.password}
              onChange={event =>
                this.onTxtChange('password', event.target.value)
              }
              type="password"
            />
            <label htmlFor="password">Password</label>
          </div>
        </div>

        <div className="row">
          <div className="input-field col s12">
            <button className="btn waves-effect waves-light border-round col s12">
              Login
            </button>
          </div>
        </div>

        {iconLoading}

        <div className="row">
          <div className="input-field col s12">
            <p className="margin right-align medium-small">
              <span
                className="linkRecordar"
                onClick={event => this.props.onToogleForm()}
              >
                Olvide mi password
                <i className="fas fa-arrow-circle-right ml-5" />
              </span>
            </p>
          </div>
        </div>
      </form>
    );
  }
}

export default FormLogin;
