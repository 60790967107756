import React from 'react';

import ASolicitudesPage from './ASolicitudesPage';

function MisSolicitudesPage({ listaSol, onCrudSol }) {
  const listaSolPorAprobar = listaSol.filter(item => {
    return item.idEstatus === 'espera';
  });

  return (
    <ASolicitudesPage
      titulo="Solicitudes Por Evaluar"
      listaSol={listaSolPorAprobar}
      onCrudSol={onCrudSol}
    />
  );
}

export default MisSolicitudesPage;
