import React from 'react';
import { TextInput } from 'react-materialize';

function InfoSolEdit(props) {
  return (
    <div className="row ">
      <TextInput
        value={props.valor}
        s={12}
        label={props.label}
        onChange={event =>
          props.onChangePropiedad(
            event.target.value,
            props.propiedad,
            props.metaPropiedad
          )
        }
      />
    </div>
  );
}

export default InfoSolEdit;
