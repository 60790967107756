import React, { Component } from 'react';
import { Button, TextInput } from 'react-materialize';
import './Table.css';
import { DebounceInput } from 'react-debounce-input';

class Table extends Component {
  constructor(props, context) {
    super(props, context);

    this.state = {
      valorFiltro: ''
    };
  }

  onFiltroChange = valorFiltro => {
    this.setState({ valorFiltro });
  };

  render() {
    const listaTH = this.props.listaHead.map((h, index) => {
      if (typeof h === 'string') {
        return <th key={index}>{h}</th>;
      } else {
        const cssTH = h.claseCSS === undefined ? '' : h.claseCSS;
        return (
          <th key={index} className={cssTH}>
            {h.label}
          </th>
        );
      }
    });

    const { fnRenderRow, idLast, onShowModal, fnFiltro } = this.props;

    let listaRows = this.props.listaRows;

    const valorFiltro = this.state.valorFiltro.trim().toLowerCase();

    if (typeof fnFiltro === 'function' && valorFiltro !== '') {
      listaRows = listaRows.filter(item => {
        return fnFiltro(valorFiltro, item);
      });
    }

    const listaTR = listaRows.map((row, index) => {
      return fnRenderRow(row, index, idLast, onShowModal);
    });

    const isAllowInsert = typeof this.props.onInsert === 'function';

    const cmdInsert = isAllowInsert ? (
      <Button
        className="waves-effect waves-light btn-small btn-default modal-trigger mrBTN"
        onClick={event => this.props.onInsert()}
      >
        <i className="fas fa-plus" />
      </Button>
    ) : null;

    const filtroBoxElement = this.getFiltroBoxElement(fnFiltro);

    return (
      <div>
        <div className="row">
          <div className="col s12">
            <div className="card card-tabs">
              <div className="card-content">
                <div className="card-title">
                  {cmdInsert}
                  <span className="tituloTabla mrBTN">{this.props.titulo}</span>
                  ({listaTR.length})
                </div>

                {filtroBoxElement}

                <div className="active">
                  <table>
                    <thead>
                      <tr>{listaTH}</tr>
                    </thead>
                    <tbody>{listaTR}</tbody>
                  </table>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }

  getFiltroBoxElement(fnFiltro) {
    if (fnFiltro === undefined) {
      return null;
    }

    return (
      <div className="divFlex">
        <i className="fas fa-filter mrBTN mt-20" />

        <DebounceInput
          minLength={2}
          debounceTimeout={500}
          className="txtFiltro"
          placeholder="Filtro"
          onChange={event => this.onFiltroChange(event.target.value)}
        />

        <div className="flexgrow" />
      </div>
    );
  }
}

export default Table;
