import axios from 'axios';

class ReqJson {
  constructor(factoryHeader = null) {
    this.isDebug = true;
    this.pathLogRequest = null;

    this.factoryHeader = factoryHeader;

    this.customHeaders = {
      Accept: 'application/json',
      'Content-Type': 'application/json'
    };
  }

  setIsDebug = () => {
    this.isDebug = true;
  };

  setModoLogResquest = pathToDirLog => {
    this.pathLogRequest = pathToDirLog;
  };

  handleCatchError = (error, url) => {
    this.consoleIfDebug(error.response);
    this.logRequest(error.response, 'error', url);

    if (error.response && typeof error.response.data !== 'undefined') {
      return error.response.data;
    }

    return {
      success: false,
      msg: error
    };
  };

  consoleIfDebug = data => {
    if (this.isDebug) {
      console.log(data);
    }
  };

  logRequest = (data, nameData, url) => {
    if (this.pathLogRequest) {
      //save to pathg
    }
  };

  requestGET = async (url, paramHeader) => {
    const self = this;

    self.consoleIfDebug(url);
    self.logRequest({ url }, 'request', url);

    const customHeaders = this.customHeaders;
    let facHeaders = {};
    if (typeof this.factoryHeader === 'function') {
      facHeaders = this.factoryHeader();
    }
    let headers = { ...paramHeader, ...customHeaders, ...facHeaders };

    try {
      return await axios
        .get(url, { headers })
        .then(function(response) {
          self.consoleIfDebug(response);
          self.logRequest(response, 'response', url);

          return response.data;
        })
        .catch(function(error) {
          return self.handleCatchError(error, url);
        });
    } catch (error) {
      return this.handleCatchError(error, url);
    }
  };

  requestPOST = async (url, dataObject, paramHeader) => {
    const self = this;

    self.consoleIfDebug(url);

    const dataObjectForLog = { ...dataObject, ...paramHeader, url };
    self.logRequest(dataObjectForLog, 'request', url);

    const customHeaders = this.customHeaders;
    let facHeaders = {};
    if (typeof this.factoryHeader === 'function') {
      facHeaders = this.factoryHeader();
    }
    let headers = { ...paramHeader, ...customHeaders, ...facHeaders };

    try {
      return await axios
        .post(url, dataObject, { headers })
        .then(function(response) {
          self.consoleIfDebug(response);
          self.logRequest(response, 'response', url);

          return response.data;
        })
        .catch(function(error) {
          return self.handleCatchError(error, url);
        });
    } catch (error) {
      return self.handleCatchError(error, url);
    }
  };

  requestDELETE = async (url, paramHeader) => {
    const self = this;

    self.consoleIfDebug(url);
    self.logRequest({ url }, 'request', url);

    const customHeaders = this.customHeaders;
    let facHeaders = {};
    if (typeof this.factoryHeader === 'function') {
      facHeaders = this.factoryHeader();
    }
    let headers = { ...paramHeader, ...customHeaders, ...facHeaders };

    try {
      return await axios
        .delete(url, headers)
        .then(function(response) {
          self.consoleIfDebug(response);
          self.logRequest(response, 'response', url);

          return response.data;
        })
        .catch(function(error) {
          return self.handleCatchError(error, url);
        });
    } catch (error) {
      return this.handleCatchError(error, url);
    }
  };

  requestPUTFile = async (url, objectFile, paramFileName) => {
    const self = this;

    self.consoleIfDebug(url);
    self.logRequest({ url }, 'request', url);


    const options = {
      headers: {
        'Content-Type': objectFile.type
      }
    };


    try {
      return await axios
          .put(url, objectFile, options )
          .then(function(response) {
            self.consoleIfDebug(response);
            self.logRequest(response, 'response', url);

            return response.data;
          })
          .catch(function(error) {
            return self.handleCatchError(error, url);
          });
    } catch (error) {
      return this.handleCatchError(error, url);
    }
  };

  uploadFilePOST = async (url, objectFile, paramFileName, paramHeader) => {
    const self = this;

    self.consoleIfDebug(url);
    self.logRequest({ url }, 'request', url);

    //Este metodo  no es json
    const customHeaders = { 'Content-Type': 'multipart/form-data' };
    let facHeaders = {};
    if (typeof this.factoryHeader === 'function') {
      facHeaders = this.factoryHeader();
    }

    let headers = { ...paramHeader, customHeaders, ...facHeaders };

    var formData = new FormData();

    formData.append(paramFileName, objectFile);

    try {
      return await axios
        .post(url, formData, headers)
        .then(function(response) {
          self.consoleIfDebug(response);
          self.logRequest(response, 'response', url);

          return response.data;
        })
        .catch(function(error) {
          return self.handleCatchError(error, url);
        });
    } catch (error) {
      return this.handleCatchError(error, url);
    }
  };
}

export default ReqJson;
