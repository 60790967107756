import React, { Component } from 'react';

/* Datos que se envian en el msg al socket LibMsgWSService
*   origen: 'mc',
    usuario:AuthService.getIsUser().usuario,
    idSol,
    tipo,
    idSession,
    idArea: AuthService.getUser().idArea,
    area:''
* */

function MsgTr({ index, row }) {
  const tipo = row.tipo.toUpperCase();

  return (
    <tr>
      <td>
        <h4 className="tituloRow">{row.nombreCliente}</h4>
      </td>
      <td>{row.area}</td>
      <td>{tipo}</td>
    </tr>
  );
}

export default MsgTr;
