import React, { Fragment } from 'react';
import HOCProtectedPage from '../HOCProtectedPage';
import TopBar from '../../pageComponents/topbar/TopBar';
import AppContext from '../../app/AppContext';

import MisSolicitudesPage from './MisSolicitudesPage';

// solicidudesPendientes ****************************

class MisSolicitudesPageHoc extends React.Component {
  render() {
    return (
      <HOCProtectedPage>
        <AppContext.Consumer>
          {context => (
            <Fragment>
              <TopBar />
              <MisSolicitudesPage
                listaSol={context.listaSol}
                onCrudSol={context.onCrudSol}
              />
            </Fragment>
          )}
        </AppContext.Consumer>
      </HOCProtectedPage>
    );
  }
}

export default MisSolicitudesPageHoc;
