import React, { Component } from 'react';

import DataService from '../../../services/dataService/DataService';
import LibToast from '../../../lib/LibToast';

import Table from '../../../pageComponents/table/Table';
import PermisoVendedorTr from './PermisoVendedorTr';
import LibMsgFlash from '../../../services/msgFlash/LibMsgFlash';

class PermisoVendedorPage extends Component {
  constructor(props, context) {
    super(props, context);
    this.state = {
      listaPermisoV: [],
      idLast: null
    };
  }

  async componentDidMount() {
    const respuesta = await DataService.Mc.PermisoV.IndexFull();

    if (!respuesta.success) {
      LibToast.alert(respuesta.msg);
      return;
    }

    LibToast.success('Datos recibidos Permisos de Vendedores');

    this.setState({ listaPermisoV: respuesta.data.lista });

    const idFlash = LibMsgFlash.getIdLast();

    if (idFlash) {
      const dataFlash = LibMsgFlash.getMsg();
      this.setState({ idLast: idFlash });
      setTimeout(() => {
        LibToast.fromDataFlash(dataFlash);
      }, 300);
    }
  }

  render() {
    const { listaPermisoV, idLast } = this.state;

    const listaHead = [
      { label: '', claseCSS: 'tdcmd' },
      'Usuario',
      'email',
      { label:'Tiene Permiso',claseCSS:'textCenter'},
      'Permiso / Área de Ventas'
    ];

    const fn = (row, index, idLast) => {
      const key = 'k' + (row._id || 'new');

      return (
        <PermisoVendedorTr index={index} row={row} key={key} idLast={idLast} />
      );
    };

    return (
      <Table
        titulo="Permisos de Vendedor"
        listaHead={listaHead}
        listaRows={listaPermisoV}
        fnRenderRow={fn}
        idLast={idLast}
      />
    );
  }
}

export default PermisoVendedorPage;
