import React, {Component, Fragment} from 'react';
import './ImgBrowser.css';

import ItemDocSolicitudMc from "./ItemDocSolicitudMc";


class ImgBrowserMC extends Component {
  constructor(props, context) {
    super(props, context);

  }


  onImageAdd() {

    console.log('agregar la imagen alista sol')
    //actualizar la lista de img y llamar actualziar el contador de img
  }

  render() {

    const {sol}  = this.props;


    const documento = {
      _id: "mc"
    };

    return (
        <Fragment>

          <ItemDocSolicitudMc
              sol={sol}
              index="mcdoc"
              documento={documento}
              onImageAdd={img => this.onImageAdd(img)}
          />

        </Fragment>

    );


  }
}

export default ImgBrowserMC;
