import apiLogin from './secciones/apiLogin';
import apiAdmin from './secciones/apiAdmin';
import apiMc from './secciones/apiMc';

const DataApi = {
  Login: apiLogin,
  Admin: apiAdmin,
  Mc: apiMc
};

export default DataApi;
