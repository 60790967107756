import React from 'react';

function SolicitudEstatusTr(props) {
  const { index, row } = props;

  return (
    <tr key={index}>
      <td>
        <h4 className="tituloRow">{row.estatus}</h4>
      </td>
      <td>{row.posicion}</td>
    </tr>
  );
}

export default SolicitudEstatusTr;
