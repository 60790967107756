import React, { Component } from 'react';
import LibToast from '../../lib/LibToast';
import LibValidacion from '../../lib/LibValidacion';

class FormRecuperar extends Component {
  constructor(props, context) {
    super(props, context);

    this.state = {
      email: props.email,
      password: '',
      isEnProceso: props.isEnProceso
    };
  }

  onTxtChange = (campo, valor) => {
    let dic = {};
    dic[campo] = valor;
    this.setState(dic);

    if (campo === 'email') {
      this.props.onUpdateEmail(valor);
    }
  };

  onSubmit = async event => {
    event.preventDefault();

    const email = this.state.email.trim();

    let isValid = true;

    if (email === '') {
      LibToast.alert('Falta el Email');
      isValid = false;
    } else {
      //validar email
      isValid = LibValidacion.isEmailValid(email);
    }

    if (!isValid) {
      return;
    }

    await this.props.onSubmitForm(email);
  };

  render() {
    const iconLoading = this.props.isEnProceso && (
      <div className="center-align">
        <i className="fa fa-cog fa-spin " />
        <span className="pl-1"> En Proceso</span>
      </div>
    );

    return (
      <form onSubmit={this.onSubmit} className="login-form">
        <div className="row margin">
          <div className="input-field col s12">
            <i className="fa fa-user prefix" />

            <input
              id="emailrec"
              type="email"
              value={this.state.email}
              onChange={event => this.onTxtChange('email', event.target.value)}
            />
            <label htmlFor="emailrec" className="center-align active">
              email
            </label>
          </div>
        </div>

        <div className="row">
          <div className="input-field col s12">
            <button className="btn waves-effect waves-light border-round col s12">
              Solicitar Recuperación
            </button>
          </div>
        </div>

        {iconLoading}

        <div className="row">
          <div className="input-field col s12">
            <p className="margin  medium-small">
              <span
                className="linkRecordar"
                onClick={event => this.props.onToogleForm()}
              >
                <i className="fas fa-arrow-circle-left mr-5" />
                Ingresar con Password
              </span>
            </p>
          </div>
        </div>
      </form>
    );
  }
}

export default FormRecuperar;
