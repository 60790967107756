import React, { Fragment } from 'react';
import { Button } from 'react-materialize';
import UrlPages from '../../UrlPages';
import DetalleUXA from './DetalleUXA';

function AreaVentasTr(props) {
  const { row, idLast } = props;

  const onEdit = id => {
    window.location = `${UrlPages.ADM_AREAVENTAS_CRUD}/update/${id}`;
  };

  const TrCss = idLast === row._id ? 'lastModel' : '';

  return (
    <tr key={row._id} className={TrCss}>
      <td>
        <Button
          className="waves-effect waves-light btn-small blue"
          onClick={event => onEdit(row._id)}
        >
          <i className="fas fa-edit" />
        </Button>
      </td>
      <td>
        <h4 className="tituloRow">{row.area}</h4>
      </td>
      <td>{row.areaTipo}</td>
      <td>{row.prioridad}</td>
      <td>
        <DetalleUXA
          listaUXArea={row.listaUXArea}
          tituloU="Vendedores Asociados"
          tipoUsuario="v"
        />
        <DetalleUXA
          listaUXArea={row.listaUXArea}
          tituloU="Mesa de Control asignada"
          tipoUsuario="mc"
        />
      </td>
      <td>{row.comentario}</td>
    </tr>
  );
}

export default AreaVentasTr;
