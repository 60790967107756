import React, { Component } from 'react';

import './login.css';
import DataService from '../../services/dataService/DataService';
import AuthService from '../../services/authService/AuthService';
import LibToast from '../../lib/LibToast';

import FormLogin from './FormLogin';
import FormRecuperar from './FormRecuperar';
import FormRecuperacionExe from './FormRecuperacionExe';

const ID_TIPO_FORM_LOGIN = 'form_login';
const ID_TIPO_FORM_RECUPERAR = 'form_recuperar';
const ID_TIPO_FORM_RECUPERAR_EXE = 'form_recuperar_paso2';

class LoginPage extends Component {
  constructor(props, context) {
    super(props, context);

    this.state = {
      isEnProceso: false,
      idTipoForm: ID_TIPO_FORM_LOGIN,
      email: ''
    };

    this.onSetIsEnProceso.bind(this);
    this.onSubmitLogin.bind(this);
    this.onSubmitLoginRec.bind(this);
    this.onSubmitLoginRecExe.bind(this);
    this.onToogleForm.bind(this);
    this.onUpdateEmail.bind(this);
  }

  onUpdateEmail = email => {
    this.setState({ email });
  };

  onToogleForm = () => {
    const idTipoForm =
      this.state.idTipoForm === ID_TIPO_FORM_RECUPERAR
        ? ID_TIPO_FORM_LOGIN
        : ID_TIPO_FORM_RECUPERAR;

    this.setState({
      idTipoForm
    });
  };

  onSubmitLoginRec = async email => {
    this.setState({
      isEnProceso: true
    });

    let respuesta = await DataService.Login.requestRecPass(email);

    if (!respuesta.success) {
      LibToast.alert(respuesta.msg);
      this.setState({
        isEnProceso: false
      });
      return;
    }

    this.setState({
      isEnProceso: false
    });

    LibToast.success(respuesta.data.msg);

    this.setState({
      idTipoForm: ID_TIPO_FORM_RECUPERAR_EXE
    });
  };

  onSubmitLoginRecExe = async (token, newPassword) => {
    this.setState({
      isEnProceso: true
    });

    const email = this.state.email;

    let respuesta = await DataService.Login.executeRecPass(
      email,
      token,
      newPassword
    );

    if (!respuesta.success) {
      LibToast.alert(respuesta.msg);
      this.setState({
        isEnProceso: false
      });
      return;
    }

    this.setState({
      isEnProceso: false
    });

    LibToast.success(respuesta.data.msg);

    this.setState({
      idTipoForm: ID_TIPO_FORM_LOGIN
    });
  };

  onSubmitLogin = async (email, password) => {
    this.setState({
      isEnProceso: true
    });

    /*Hacer el proceso de login*/

    let respuesta = await DataService.Login.ingresar(email, password);

    if (!respuesta.success) {
      LibToast.alert(respuesta.msg);
      this.setState({
        isEnProceso: false
      });
      return;
    }

    LibToast.success('Bienvenido!');

    const identidad = respuesta.data;

    if (!identidad.public.isMC) {
      LibToast.alert('No tiene permiso de mesa de control');
    }

    AuthService.registrarLogin(identidad.public, identidad.token, true);

    this.props.onLogIn();
  };

  onSetIsEnProceso = async b => {
    this.setState({
      isEnProceso: b
    });
  };

  render() {
    //verificar la authenticacion
    if (AuthService.getIsAuthenticated()) {
      this.props.onLogIn();
      return null;
    }

    let formLogin = null;
    if (this.state.idTipoForm === ID_TIPO_FORM_LOGIN) {
      formLogin = (
        <FormLogin
          onSetIsEnProceso={this.onSetIsEnProceso}
          onSubmitForm={this.onSubmitLogin}
          onToogleForm={this.onToogleForm}
          onUpdateEmail={this.onUpdateEmail}
          email={this.state.email}
          isEnProceso={this.state.isEnProceso}
        />
      );
    }

    let formRecuperar = null;
    if (this.state.idTipoForm === ID_TIPO_FORM_RECUPERAR) {
      formRecuperar = (
        <FormRecuperar
          onSetIsEnProceso={this.onSetIsEnProceso}
          onSubmitForm={this.onSubmitLoginRec}
          onToogleForm={this.onToogleForm}
          onUpdateEmail={this.onUpdateEmail}
          email={this.state.email}
          isEnProceso={this.state.isEnProceso}
        />
      );
    }

    let formRecuperarExe = null;
    if (this.state.idTipoForm === ID_TIPO_FORM_RECUPERAR_EXE) {
      formRecuperarExe = (
        <FormRecuperacionExe
          onSetIsEnProceso={this.onSetIsEnProceso}
          onSubmitForm={this.onSubmitLoginRecExe}
          onToogleForm={this.onToogleForm}
          onUpdateEmail={this.onUpdateEmail}
          email={this.state.email}
          isEnProceso={this.state.isEnProceso}
        />
      );
    }

    return (
      <div className="row">
        <div className="col s12">
          <div className="container">
            <div id="login-page" className="row">
              <div className="col s12  l6 z-depth-4 card-panel border-radius-6 login-card bg-opacity-8">
                <div className="row">
                  <div className="input-field col s12">
                    <h5 className="ml-4 center-align">
                      Control de Solicitudes MC
                    </h5>
                  </div>
                </div>
                {formLogin}
                {formRecuperar}
                {formRecuperarExe}
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default LoginPage;
