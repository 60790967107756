import LibTexto from '../../lib/LibTexto';

class Singleton {
  KEY_LOCALSTORAGE_TOKEN = 'solmc_token';
  KEY_LOCALSTORAGE_TOKEN_TSVALID = 'solmc_token_tsvalid';
  KEY_LOCALSTORAGE_PUBLICDATA = 'solmc_public_data';

  constructor() {
    this.public_data = null;
    this.token = null;
    this.tsValid = 0;

    //verificar si tneemos localsotara

    if (
      window.localStorage.getItem(this.KEY_LOCALSTORAGE_TOKEN) &&
      window.localStorage.getItem(this.KEY_LOCALSTORAGE_PUBLICDATA) &&
      window.localStorage.getItem(this.KEY_LOCALSTORAGE_TOKEN_TSVALID)
    ) {
      this.token = window.localStorage.getItem(this.KEY_LOCALSTORAGE_TOKEN);

      let texto = window.localStorage.getItem(this.KEY_LOCALSTORAGE_PUBLICDATA);
      this.public_data = JSON.parse(texto);

      this.tsValid = parseInt(
        window.localStorage.getItem(this.KEY_LOCALSTORAGE_TOKEN_TSVALID)
      );
      const tsNow = Date.now();
      if (this.tsValid < tsNow) {
        this.setLogOut();
      }
    }
  }

  registrarLogin(public_data, token, isSaveLocalStorage = false) {
    if (!token || !public_data) {
      throw new Error('No se puede hacer session sin datos correctos');
    }

    this.token = token;

    public_data.idSession = LibTexto.getRandomString();

    this.public_data = public_data;

    if (isSaveLocalStorage && token && public_data) {
      window.localStorage.setItem(this.KEY_LOCALSTORAGE_TOKEN, token);

      const json = JSON.stringify(public_data);
      window.localStorage.setItem(this.KEY_LOCALSTORAGE_PUBLICDATA, json);

      const ts = Date.now();
      this.tsValid = ts + public_data.lapso;
      window.localStorage.setItem(
        this.KEY_LOCALSTORAGE_TOKEN_TSVALID,
        this.tsValid
      );
    }
  }

  getIsAuthenticated() {
    //validar que existe la session enlocalstorage
    if (this.token === null) {
      return false;
    }
    const tsCurrent = Date.now();

    //validar el tiempo de vida de la sesion
    if (this.tsValid < tsCurrent) {
      return false;
    }

    return true;
  }

  getUser() {
    return this.public_data;
  }

  getIsAdmin() {
    return this.public_data.isAdmin === true;
  }

  getIsMC() {
    return this.public_data.isMC === true;
  }

  getIsMCAdmin() {
    return this.public_data.isMCAdmin === true;
  }

  getToken() {
    return this.token;
  }

  setLogOut() {
    this.token = null;
    this.public_data = null;
    this.tsValid = 0;

    window.localStorage.removeItem(this.KEY_LOCALSTORAGE_TOKEN);
    window.localStorage.removeItem(this.KEY_LOCALSTORAGE_TOKEN_TSVALID);
    window.localStorage.removeItem(this.KEY_LOCALSTORAGE_PUBLICDATA);
  }

  updateData(dataUpdate) {
    //lista campos permitidos
    const listaCamposAllow = ['email', 'nombre', 'nick'];

    listaCamposAllow.forEach(c => {
      if (dataUpdate[c] !== undefined) {
        this.public_data[c] = dataUpdate[c];
      }
    });
  }
}

const AuthService = new Singleton();

export default AuthService;
