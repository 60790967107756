import React from 'react';
import Table from '../../pageComponents/table/Table';
import MsgTr from './MsgTr';

function MsgPage({ listaMsg }) {
  const listaHead = ['Solicitud', 'Área', 'Usuario'];

  const fn = (row, index, idLast) => {
    const key = index.toString();

    return <MsgTr index={index} row={row} key={key} />;
  };

  return (
    <Table
      titulo="Mensajes Recibidos"
      listaHead={listaHead}
      listaRows={listaMsg}
      fnRenderRow={fn}
    />
  );
}

export default MsgPage;
