import React, {Fragment} from 'react';
import {Button} from 'react-materialize';
import UrlPages from '../../UrlPages';
import LibToast from "../../../lib/LibToast";
import DataService from "../../../services/dataService/DataService";

const sendInivitacion=async (id, nombre)=>{
  const ope= await DataService.Admin.Usuario.SendInvitacionV(id);

  if(ope.success=== true){
    LibToast.success('Invitación Enviada a' + nombre);
  }else{
    LibToast.alert(ope.message);
  }

};


function PermisoVendedorTr(props) {
  const {index, row, idLast} = props;

  const TrCss = idLast === row._id ? 'lastModel' : '';

  const iconIsPermiso = row.isPermisoV ? <i className="fas fa-check"/> : null;

  let elemPermisoV = null;
  let elemInvitacionV = null;




  if (row.permisoV) {
    const liVendedor = row.permisoV.isVendedor ? <li> Es Vendedor</li> : null;
    const liAdmin = row.permisoV.isAdmin ? (
        <li>
          Es Administrador <i className="fas fa-star"/>
        </li>
    ) : null;

    let olAsignacion = <p className="textoLight">-Sin Areas Asignadas-</p>;
    if (row.permisoV.listaAreas && row.permisoV.listaAreas.length > 0) {
      const lista = row.permisoV.listaAreas.map((item, index) => {
        return (
            <li title={item.idArea} key={index}>
              {item.area}
            </li>
        );
      });
      olAsignacion = <ol>{lista}</ol>;
    }

    elemPermisoV = (
        <Fragment>
          <ul>
            {liVendedor}
            {liAdmin}
          </ul>
          <h3 className="subtituloTD">
            <i className="fas fa-home"/>
            Áreas a las que pertenece
          </h3>
          {olAsignacion}
        </Fragment>
    );


    elemInvitacionV = (
        <Button title="Enviar Invitación al sistema"
                className="waves-effect waves-light btn-small  mt-20 black"
                onClick={event=>sendInivitacion(row.permisoV.idUsuario, row.permisoV.usuario)}
        >
          <i className="fas fa-envelope"/>
        </Button>
    );
  }

  const onEdit = id => {
    window.location = `${UrlPages.ADM_PERMISO_VENDEDOR_CRUD}/update/${id}`;
  };

  return (
      <tr key={index} className={TrCss}>
        <td>
          <Button
              className="waves-effect waves-light btn-small blue"
              onClick={event => onEdit(row._id)}
          >
            <i className="fas fa-edit"/>
          </Button>
        </td>
        <td>
          <h4 className="tituloRow">{row.nombre}</h4>
        </td>
        <td>{row.email}</td>
        <td className="textCenter">
          <div>
            {iconIsPermiso}
          </div>
          {elemInvitacionV}
        </td>
        <td>{elemPermisoV}</td>
      </tr>
  );
}

export default PermisoVendedorTr;
