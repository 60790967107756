import React from 'react';

function MsgEnProceso(props) {

  const isEnProceso = props.isEnProceso;

  if (!isEnProceso) {
    return null;
  }

  return (
      <div className="msgEnProceso">
        <i className="fa fa-cog fa-spin "/>
        <span className="pl-1"> En Proceso</span>
      </div>
  );
}

export default MsgEnProceso;
