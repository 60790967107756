import React from 'react';
import ObserverTopBarEvent from './ObserverTopBarEvent';
import AuthService from '../../services/authService/AuthService';
import UrlPages from '../../pages/UrlPages';
import { Link } from 'react-router-dom';

import { Dropdown } from 'react-materialize';
import FactoryMenuMC from './menu/FactoryMenuMC';
import FactoryMenuAdm from './menu/FactoryMenuAdm';

class TopBar extends React.Component {
  constructor(props, context) {
    super(props, context);

    let nick = '';

    if (AuthService.getIsAuthenticated()) {
      nick = AuthService.getUser().nick;
    }

    this.state = {
      nick,
      isAdmin: AuthService.getIsAdmin(),
      isMC: AuthService.getIsMC(),
      isMCAdmin: AuthService.getIsMCAdmin()
    };

    ObserverTopBarEvent.registrarFnOnNickchange(this.onChangeNick);
  }

  onChangeNick = nick => {
    this.setState({
      nick
    });
  };

  onLogOut = () => {
    this.setState({
      isAutenticado: false
    });

    AuthService.setLogOut();
    window.location = UrlPages.LOGIN;
  };

  render() {
    const nombreUsuario = this.state.nick;

    const menuMC = FactoryMenuMC(this.state.isMC);
    const menuAdm = FactoryMenuAdm(this.state.isMCAdmin, this.state.isAdmin);

    return (
      <nav>
        <div className="nav-wrapper divFlex navbar-dark gradient-45deg-light-blue-cyan">
          <Link to={UrlPages.DASHBOARD} className="divFlex ml-5 mr-20">
            <img src="/logo2.png" alt="Beryhuert" className="logoBH" />
            <span className="titulaAPP ml-20">CONTROL DE SOLICITUDES</span>
          </Link>

          <ul className=" hide-on-med-and-down ml-20">
            {menuMC}

            <li>
              <Link to={UrlPages.MSG_INDEX}>
                <i className="fas fa-comments left" />
                Mensajes
              </Link>
            </li>

            {menuAdm}
          </ul>

          <div className="flexgrow"></div>

          <ul className=" hide-on-med-and-down ml-20">
            <li>
              <Dropdown
                trigger={
                  <span className="aNavWithIcon mr-5">
                    <>
                      <i className="fas fa-user" />
                      {nombreUsuario}
                    </>
                  </span>
                }
              >
                <Link to={UrlPages.CUENTA}>Actualizar</Link>

                <Link to={UrlPages.LOGOUT}>Salir</Link>
              </Dropdown>
            </li>
          </ul>
        </div>
      </nav>
    );
  }
}

export default TopBar;
