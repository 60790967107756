import React, { Fragment } from 'react';
import Table from '../../pageComponents/table/Table';

import './solicitudes.css';
import TrSolicitud from './TrSolicitud';
import { Button, Modal } from 'react-materialize';
import DataService from '../../services/dataService/DataService';
import LibToast from '../../lib/LibToast';
import SolDeleteModalData from '../../pageComponents/modalData/SolDeleteModalData';

const listaHead = [
  { label: '', claseCSS: 'tdcmd' },
  { label: 'Estatus', claseCSS: 'tdestatus' },
  { label: 'Fecha', claseCSS: 'thsolfecha' },
  { label: 'Tipo', claseCSS: 'thsoltipo' },
  { label: 'Vendedor', claseCSS: 'thsolVendedor' },
  { label: 'Cliente', claseCSS: 'thsolCliente' },
  { label: '', claseCSS: 'tdCam' },
  { label: 'Comentario' },
  { label: '', claseCSS: 'tdcmd' }
];

class ASolicitudesPage extends React.Component {
  constructor(props, context) {
    super(props, context);
    this.state = {
      rowDelete: null
    };

    this.isDeleteInProcess = false;
  }

  onShowModalDelete = rowDelete => {
    this.setState({ rowDelete });
    const modal = window.M.Modal.getInstance(
      document.getElementById('modalDeleteSol')
    );
    modal.open();
  };

  onExeDelete = async () => {
    if (this.isDeleteInProcess) {
      return;
    }

    this.isDeleteInProcess = true;

    const id = this.state.rowDelete._id;
    const respuesta = await DataService.Mc.deleteSol(id);

    this.isDeleteInProcess = false;

    if (!respuesta.success) {
      LibToast.alert(respuesta.msg);
      return;
    }

    this.props.onCrudSol.delete(id, this.state.rowDelete.nombreCliente);

    LibToast.success(
      'Solicitud  ' + this.state.rowDelete.nombreCliente + ' Eliminada'
    );
    const modal = window.M.Modal.getInstance(
      document.getElementById('modalDeleteSol')
    );
    modal.close();
  };

  render() {
    const { listaSol, titulo } = this.props;

    const fn = (row, index, idLast, onShowModal) => {
      return <TrSolicitud row={row} onShowModal={onShowModal} key={index} />;
    };

    const onShowModal = {
      delete: this.onShowModalDelete
    };

    let contenidoModal = null;
    const rowDelete = this.state.rowDelete;

    if (rowDelete) {
      contenidoModal = <SolDeleteModalData sol={rowDelete} />;
    }

    const listaPropEnFiltro = ['nombreCliente', 'nick', 'nombreVendedor','idEstatus'];

    const fnFiltro = (texto, item) => {
      let isConincidencia = false;
      listaPropEnFiltro.forEach(p => {
        if (item[p].toLowerCase().includes(texto)) {
          isConincidencia = true;
        }
      });
      return isConincidencia;
    };

    return (
      <Fragment>
        <Table
          titulo={titulo}
          listaHead={listaHead}
          listaRows={listaSol}
          onShowModal={onShowModal}
          fnRenderRow={fn}
          fnFiltro={fnFiltro}
        />
        <Modal id="modalDeleteSol">
          {contenidoModal}
          <div>
            <Button className="red small" onClick={event => this.onExeDelete()}>
              Eliminar
            </Button>
          </div>
        </Modal>
      </Fragment>
    );
  }
}

export default ASolicitudesPage;
