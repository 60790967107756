import React, { Fragment } from 'react';
import LibTexto from '../../lib/LibTexto';
import { Tabs, Tab } from 'react-materialize';
import ImgBrowser from './ImgBrowser';
import './SolBrowser.css';
import ImgBrowserMC from "./ImgBrowserMC";

const getListaTab = sol => {
  const lista = sol.listaDocTipo.map((item, index) => {
    const idTab = LibTexto.getRandomString(5);

    const iconOpcional =
      item.isNoRequerido === true ? (
        <span style={{ color: '#c3c3c3', marginLeft: 5, fontSize: 10 }}>
          Opcional
        </span>
      ) : null;

    const idDocTipo = item._id;

    const listaImg = sol.listaImg.filter(item => {
      return item.idDocTipo === idDocTipo;
    });

    const numIcons = listaImg.length;
    const labNumIcons = (
      <span className="labNumIcons">
        {numIcons} <i className="fas fa-camera" />
      </span>
    );

    const titulo = (
      <div>
        {`${index + 1}) ${item.docTipo}`}
        <div>
          {labNumIcons}
          {iconOpcional}
        </div>
      </div>
    );

    //buscar las imagenes

    return (
      <Tab title={titulo} key={idTab}>
        <Fragment>
          <ImgBrowser sol={sol} listaImg={listaImg} />
        </Fragment>
      </Tab>
    );
  });

  const numMcIcon=  (sol.listaImg || [] ).filter( itemImg=>{
    return itemImg.idDocTipo.startsWith('mc');
  }).length;

  const labNumMcIcons = (
      <span className="labNumIcons">
        {numMcIcon} <i className="fas fa-camera" />
      </span>
  );

  const tituloDocMC = (
      <div>
        <div className="labelMC">
          Mesa de Control
        </div>
        {labNumMcIcons}
      </div>
  );

  lista.push(
      <Tab title={tituloDocMC} key="mc">
        <Fragment>
          <ImgBrowserMC
              sol={sol}
          />
        </Fragment>
      </Tab>
  );
  return lista;
};

function SolBrowser(props) {
  const sol = props.sol;

  const listaTab = getListaTab(sol);

  return <Tabs className="tab-demo z-depth-1">{listaTab}</Tabs>;
}

export default SolBrowser;
