import configApi from '../configApi';
import ApiReqJson from '../ApiReqJson';

const apiLogin = {
  ingresar: async (email, password) => {
    const url = configApi.urlBase + `/login`;
    return await ApiReqJson.requestPOST(url, { email, password });
  },
  requestRecPass: async email => {
    const url = configApi.urlBase + `/recpass`;
    const ope = 'request';
    return await ApiReqJson.requestPOST(url, { email, ope });
  },
  executeRecPass: async (email, token, newPassword) => {
    const url = configApi.urlBase + `/recpass`;
    const ope = 'execute';
    return await ApiReqJson.requestPOST(url, {
      email,
      ope,
      token,
      newPassword
    });
  }
};

export default apiLogin;
