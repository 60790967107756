import configApi from '../configApi';
import ApiReqJson from '../ApiReqJson';

const ope = {
  index: 'index',
  create: 'create',
  read: 'read',
  update: 'update',
  delete: 'delete'
};

const apiAdmin = {
  Usuario: {
    Index: async () => {
      const url = configApi.urlBase + `/admin/usuario_read?ope=index`;
      return await ApiReqJson.requestGET(url);
    },
    Detalle: async id => {
      const url = configApi.urlBase + `/admin/usuario_read?ope=read&id=${id}`;
      return await ApiReqJson.requestGET(url);
    },
    Update: async (id, dataUpdate) => {
      const url = configApi.urlBase + `/admin/usuario_crud`;

      const dataObject = {
        ope: ope.update,
        data: {
          id,
          ...dataUpdate
        }
      };

      return await ApiReqJson.requestPOST(url, dataObject);
    },
    Delete: async id => {
      const url = configApi.urlBase + `/admin/usuario_crud`;
      const dataObject = {
        ope: ope.delete,
        data: {
          id
        }
      };

      return await ApiReqJson.requestPOST(url, dataObject);
    },
    Create: async dataCreate => {
      const url = configApi.urlBase + `/admin/usuario_crud`;
      const dataObject = {
        ope: ope.create,
        data: {
          ...dataCreate
        }
      };

      return await ApiReqJson.requestPOST(url, dataObject);
    },
    SendInvitacionV: async (id) => {

      const url = configApi.urlBase + `/admin/usuario_invitacionvendedor?id=${id}`;

      return await ApiReqJson.requestGET(url);
    }
  },
  SolTipo: {
    Index: async () => {
      const url = configApi.urlBase + `/admin/soltipo_read?ope=index`;
      return await ApiReqJson.requestGET(url);
    },
    Detalle: async id => {
      const url = configApi.urlBase + `/admin/soltipo_read?ope=read&id=${id}`;
      return await ApiReqJson.requestGET(url);
    },
    Update: async (id, dataUpdate) => {
      const url = configApi.urlBase + `/admin/soltipo_crud`;

      const dataObject = {
        ope: ope.update,
        data: {
          id,
          ...dataUpdate
        }
      };

      return await ApiReqJson.requestPOST(url, dataObject);
    },
    Delete: async id => {
      const url = configApi.urlBase + `/admin/soltipo_crud`;
      const dataObject = {
        ope: ope.delete,
        data: {
          id
        }
      };

      return await ApiReqJson.requestPOST(url, dataObject);
    },
    Create: async dataCreate => {
      const url = configApi.urlBase + `/admin/soltipo_crud`;
      const dataObject = {
        ope: ope.create,
        data: {
          ...dataCreate
        }
      };

      return await ApiReqJson.requestPOST(url, dataObject);
    }
  }
};

export default apiAdmin;
