import React from 'react';
import { Button } from 'react-materialize';
import UrlPages from '../../UrlPages';

function PermisoVendedorTr(props) {
  const { row, idLast } = props;

  const onEdit = id => {
    window.location = `${UrlPages.ADM_USUARIO_CRUD}/update/${id}`;
  };

  const TrCss = idLast === row._id ? 'lastModel' : '';

  const iconAdmin = row.isAdmin ? <i className="fas fa-check" /> : null;
  const iconIsActivo = row.isActivo ? <i className="fas fa-thumbs-up" /> : null;

  return (
    <tr key={row._id} className={TrCss}>
      <td>
        <Button
          className="waves-effect waves-light btn-small blue"
          onClick={event => onEdit(row._id)}
        >
          <i className="fas fa-edit" />
        </Button>
      </td>
      <td>
        <small>{row.nick}</small>
        <h4 className="tituloRow">{row.nombre}</h4>
      </td>
      <td>{row.email}</td>
      <td>{iconAdmin}</td>
      <td>{iconIsActivo}</td>
      <td>{row.intentosLogin}</td>
    </tr>
  );
}

export default PermisoVendedorTr;
