import React from 'react';
import HOCProtectedPage from '../../HOCProtectedPage';
import TopBar from '../../../pageComponents/topbar/TopBar';

import PermisoMCPage from './PermisoMCPage';

function PermisoMCPageHOC() {
  return (
    <HOCProtectedPage>
      <TopBar />
      <PermisoMCPage />
    </HOCProtectedPage>
  );
}

export default PermisoMCPageHOC;
