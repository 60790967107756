import React, { Component } from 'react';
import Table from '../../../pageComponents/table/Table';
import AreaVentasTr from './AreaVentasTr';

import UrlPages from '../../UrlPages';
import DataService from '../../../services/dataService/dataApi/DataApi';
import LibToast from '../../../lib/LibToast';
import LibMsgFlash from '../../../services/msgFlash/LibMsgFlash';

class AreaVentasPage extends Component {
  constructor(props, context) {
    super(props, context);

    this.state = {
      listaAreas: [],
      idLast: null
    };
  }

  async componentDidMount() {
    const respuesta = await DataService.Mc.AreaVentas.Index();

    if (!respuesta.success) {
      LibToast.alert(respuesta.msg);
      return;
    }

    LibToast.success('Datos recibidos Áreas de Ventas');

    this.setState({ listaAreas: respuesta.data.lista });

    const idFlash = LibMsgFlash.getIdLast();

    if (idFlash) {
      const dataFlash = LibMsgFlash.getMsg();
      this.setState({ idLast: idFlash });
      setTimeout(() => {
        LibToast.fromDataFlash(dataFlash);
      }, 500);
    }
  }

  render() {
    const { listaAreas, idLast } = this.state;

    const listaHead = [
      { label: '', claseCSS: 'tdcmd' },
      'Nombre',
      'Tipo de Área',
      'Prioridad',
      'Vendedores/MC',
      'Comentario'
    ];

    const fn = (row, index, idLast) => {
      const key = 'k' + (row._id || 'new');

      return <AreaVentasTr index={index} row={row} key={key} idLast={idLast} />;
    };

    const onInsert = () => {
      window.location = UrlPages.ADM_AREAVENTAS_CRUD + '/create/new';
    };

    return (
      <Table
        titulo="Tiendas / Área de Venta"
        listaHead={listaHead}
        listaRows={listaAreas}
        fnRenderRow={fn}
        onInsert={onInsert}
        idLast={idLast}
      />
    );
  }
}

export default AreaVentasPage;
