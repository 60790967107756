import React from 'react';

import './ComSol.css';

function ComSol({ itemCom }) {
  let msgCambioEstatus = null;

  if (itemCom.isCambioE) {
    const css = itemCom.tagEstatus === 'RECHAZAR' ? 'red' : '';
    msgCambioEstatus = <h6 className={css}>{itemCom.tagEstatus}</h6>;
  }

  return (
    <div className="comSol">
      {msgCambioEstatus}
      <h6>
        <i className="fas fa-calendar mrBTN" />
        {itemCom.fecha} <br />
      </h6>
      <h6>
        <i className="fas fa-user mrBTN" /> {itemCom.nick}
      </h6>
      <div className="textoCom">{itemCom.comentario}</div>
    </div>
  );
}

export default ComSol;
