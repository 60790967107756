import React, { Component } from 'react';
import AppContext from './AppContext';

import AuthService from '../services/authService/AuthService';
import DataService from '../services/dataService/DataService';
import LibMsgWSService from '../services/msgWSService/LibMsgWSService';
import LibToast from '../lib/LibToast';

class ContextProvider extends Component {
  constructor(props, context) {
    super(props, context);

    this.state = {
      isLoadedDataAux: false,
      listaSolEstatus: [],
      onCrudSol: this.onCrudSol,
      listaSol: [],
      listaMsg: []
    };

    //asociar los eventos ******************************************
    LibMsgWSService.addCBEvent('sol_delete', data => {
      data.isNew = true;
      const listaMsg = [data, this.state.listaMsg];
      this.setState({ listaMsg });

      this.onCrudSol.deleteFromWS(data.idSol);
    });

    LibMsgWSService.addCBEvent('sol_cambioEstatus', async data => {
      data.isNew = true;
      const listaMsg = [data, ...this.state.listaMsg];
      this.setState({ listaMsg });

      await this.onCrudSol.cambiarEstatusFromWS(data.idSol, data.idEstatus);
    });

    LibMsgWSService.addCBEvent('sol_comentario', async data => {
      const { idSol, com } = data;

      const listaSol = this.state.listaSol.map(item => {
        if (item._id !== idSol) {
          return item;
        }

        item.listaCom.push(com);

        return item;
      });

      this.setState({ listaSol });
    });
  }

  onCrudSol = {
    delete: (id, nombreCliente) => {
      const listaSol = this.state.listaSol.filter(item => {
        return item._id !== id;
      });

      LibMsgWSService.sol.delete(id, nombreCliente);

      this.setState({ listaSol });
    },
    update: async (id, dataUpdate) => {
      const listaSol = this.state.listaSol.map(item => {
        if (item._id === id) {
          Object.keys(dataUpdate).forEach(k => {
            item[k] = dataUpdate[k];
          });
        }

        return item;
      });

      this.setState({ listaSol });

      //await LibMsgWSService.sol.update(id, dataUpdate);
    },
    deleteFromWS: (id, nick) => {
      const listaSol = this.state.listaSol.filter(item => {
        return item._id !== id;
      });

      this.setState({ listaSol });
      LibToast.success(`${nick} ha eliminado la solicitud ${id}`);
    },

    cambiarEstatus: (id, nuevoEstatus, comentario) => {
      const listaSol = this.state.listaSol.map(item => {
        if (item._id !== id) {
          return item;
        }

        item.idEstatus = nuevoEstatus;

        return item;
      });

      this.setState({ listaSol });

      const solUpdated = this.state.listaSol.find(item => {
        return item._id === id;
      });

      LibMsgWSService.cambioEstatus(
        id,
        nuevoEstatus,
        solUpdated.nombreCliente,
        comentario
      );
    },

    cambiarEstatusFromWS: async (id, idEstatus) => {
      //buscar index;

      const listaSol = [...this.state.listaSol];

      const indexSol = listaSol.findIndex(item => {
        return item._id === id;
      });

      const respuesta = await DataService.Mc.getSol(id);
      if (!respuesta.success) {
        LibToast.alert(respuesta.msg);
        return;
      }
      const solNew = respuesta.data;
      solNew.isUpdatedFromWS = true;

      if (indexSol >= 0) {
        Object.keys(solNew).forEach(p => {
          listaSol[indexSol][p] = solNew[p];
        });
      } else {
        listaSol.unshift(solNew);
      }

      this.setState({ listaSol });
    },
    cambiarResetIsUpdatedFromWS: async id => {
      const listaSol = this.state.listaSol.map(item => {
        if (item._id !== id) {
          return item;
        }

        item.isUpdatedFromWS = false;

        return item;
      });

      this.setState({ listaSol });
    },

    addComentario: async (id, comentario) => {
      const listaSol = this.state.listaSol.map(item => {
        if (item._id !== id) {
          return item;
        }

        item.listaCom.push(comentario);
        return item;
      });
      this.setState({ listaSol });
    },
    create: sol => {
      /*Este metodo siempre viene del websoclet*/
      const listaSol = [sol, ...this.state.listaSol];
      this.setState({ listaSol });
    }
  };

  componentDidMount() {
    if (AuthService.getIsAuthenticated()) {
      this.requestDataAux(true);
    }
  }

  requestDataAux = async (isResetSessionOnError = false) => {
    if (AuthService.getIsAuthenticated()) {
      //cargar data auxiliar
      let respuesta = null;

      const listaProp = ['listaSol'];

      respuesta = await DataService.Mc.dataAuxiliar();

      if (!respuesta.success) {
        LibToast.alert(respuesta.msg);
        if (isResetSessionOnError) {
          AuthService.setLogOut();
        }
        return false;
      }

      listaProp.forEach(p => {
        const data = {};
        data[p] = [...respuesta.data[p]];
        this.setState(data);
      });

      this.setState({ isLoadedDataAux: true });
      LibToast.success('Datos iniciales recibidos');
    }
  };

  render() {
    const dataContext = {};
    Object.keys(this.state).forEach(k => {
      dataContext[k] = this.state[k];
    });

    dataContext.setData = this.setData;
    dataContext.requestDataAux = this.requestDataAux;

    return (
      <AppContext.Provider value={dataContext}>
        {this.props.children}
      </AppContext.Provider>
    );
  }
}

export default ContextProvider;
