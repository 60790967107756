import React, { Component } from 'react';

import DataService from '../../../services/dataService/DataService';
import LibToast from '../../../lib/LibToast';

import Table from '../../../pageComponents/table/Table';
import SolicitudTipoTr from './SolicitudTipoTr';
import LibMsgFlash from '../../../services/msgFlash/LibMsgFlash';
import UrlPages from '../../UrlPages';

class SolicitudTipoPage extends Component {
  constructor(props, context) {
    super(props, context);
    this.state = {
      listaSolTipo: [],
      idLast: null
    };
  }

  async componentDidMount() {
    const respuesta = await DataService.Admin.SolTipo.Index();

    if (!respuesta.success) {
      LibToast.alert(respuesta.msg);
      return;
    }

    LibToast.success('Datos recibidos Tipos de Solicitudes');

    this.setState({ listaSolTipo: respuesta.data.lista });

    const idFlash = LibMsgFlash.getIdLast();

    if (idFlash) {
      const dataFlash = LibMsgFlash.getMsg();
      this.setState({ idLast: idFlash });
      setTimeout(() => {
        LibToast.fromDataFlash(dataFlash);
      }, 500);
    }
  }

  render() {
    const { listaSolTipo, idLast } = this.state;

    const listaHead = [
      { label: '', claseCSS: 'tdcmd' },
      'Tipo',
      'Documentos',
      'Datos Adicionales',
      'Activo',
      'Comentario'
    ];

    const fn = (row, index, idLast) => {
      const key = row._id;

      return (
        <SolicitudTipoTr index={index} row={row} key={key} idLast={idLast} />
      );
    };

    const onInsert = () => {
      window.location = UrlPages.ADM_SOLTIPO_CRUD + '/create/new';
    };

    return (
      <Table
        titulo="Tipos de Solicitudes"
        listaHead={listaHead}
        listaRows={listaSolTipo}
        fnRenderRow={fn}
        onInsert={onInsert}
        idLast={idLast}
      />
    );
  }
}

export default SolicitudTipoPage;
