import './SolPage.css';

import React, { Fragment } from 'react';
import SolBrowser from './SolBrowser';
import { Button, Modal } from 'react-materialize';
import { Link } from 'react-router-dom';
import UrlPages from '../UrlPages';
import DataService from '../../services/dataService/DataService';
import LibToast from '../../lib/LibToast';
import SolDeleteModalData from '../../pageComponents/modalData/SolDeleteModalData';
import LibMsgFlash from '../../services/msgFlash/LibMsgFlash';
import EstatusSol from '../../pageComponents/EstatusSol';
import SolDictamen from './SolDictamen';
import SolUpdateModalData from '../../pageComponents/modalData/SolUpdateModalData';

class SolPage extends React.Component {
  constructor(props, context) {
    super(props, context);

    this.state = {
      sol: props.sol,
      isShowModal: false,
      isShowModalDictaminar: false,
      isShowModalUpdate: false,
      dictamen: {
        idEstatus: null,
        com: ''
      }
    };

    this.isDeleteInProcess = false;
    this.isUpdateInProcess = false;
  }

  /*Modal Delete *********************************************** */
  onShowModalDelete = () => {
    this.setState({ isShowModal: true });

    const modal = window.M.Modal.getInstance(
      document.getElementById('modalDeleteSol')
    );
    modal.open();
  };

  onExeDelete = async () => {
    if (this.isDeleteInProcess) {
      return;
    }

    this.isDeleteInProcess = true;

    const sol = this.state.sol;
    const id = sol._id;
    const respuesta = await DataService.Mc.deleteSol(id);

    this.isDeleteInProcess = false;

    if (!respuesta.success) {
      LibToast.alert(respuesta.msg);
      return;
    }

    this.props.onCrudSol.delete(id);

    const msg = `Solicitud de ${sol.nombreCliente} eliminada`;
    LibMsgFlash.setMsgSuccess(msg);
    window.location = UrlPages.MIS_SOLICITUDES;
  };

  getCmdEliminar = sol => {
    if (sol.idEstatus === 'aprob' || sol.idEstatus === 'rechazada') {
      return (
        <Button className="cmdSol gray btn-small" title="Eliminar">
          <i className="fas fa-trash" />
        </Button>
      );
    }

    return (
      <Button
        className="cmdSol red btn-small "
        title="Eliminar"
        onClick={event => {
          this.onShowModalDelete();
        }}
      >
        <i className="fas fa-trash " />
      </Button>
    );
  };

  /*Modal Edita *********************************************** */

  onShowModalUpdate = sol => {
    if (sol.idEstatus === 'aprob') {
      LibToast.alert('La solicitud ya esta aprobada y no se puede modificar');
      return;
    }

    this.setState({ isShowModalUpdate: true });

    const modal = window.M.Modal.getInstance(
      document.getElementById('modalUpdateSol')
    );
    modal.open();
  };

  onExeUpdate = async listaData => {
    if (this.isUpdateInProcess) {
      return;
    }

    this.isUpdateInProcess = true;

    const sol = this.state.sol;
    const id = sol._id;

    const dataUpdate = {};

    dataUpdate.nombreCliente = listaData.find(item => {
      return item.propiedad === 'nombreCliente';
    }).valor;

    //crear dic de datos
    const dicInfo = {};

    listaData
      .filter(item => {
        return item.metaPropiedad === 'listaInfo';
      })
      .forEach(item => {
        dicInfo[item.propiedad] = item.valor;
      });

    //hacemos copia del listaInfo para mandarlo con la actualizcion
    dataUpdate.listaInfo = [...sol.listaInfo];

    dataUpdate.listaInfo = dataUpdate.listaInfo.map(item => {
      item.valor = dicInfo[item.nombre];
      return item;
    });

    //lista de cambios
    const respuesta = await DataService.Mc.updateSol(id, dataUpdate);

    this.isUpdateInProcess = false;

    if (!respuesta.success) {
      LibToast.alert(respuesta.msg);
      return;
    }

    LibToast.success('Solicitud Actualizada');

    this.setState({ isShowModalUpdate: false });

    const modal = window.M.Modal.getInstance(
      document.getElementById('modalUpdateSol')
    );
    modal.close();

    this.props.onCrudSol.update(id, dataUpdate);
  };

  /* render **************************************************** */

  render() {
    const { sol, isShowModal, isShowModalUpdate } = this.state;

    /* modal delete ****************** */
    let contenidoModal = null;

    if (isShowModal) {
      contenidoModal = <SolDeleteModalData sol={sol} />;
    }

    /* modal update ****************** */
    let contenidoModalUpdate = null;
    if (isShowModalUpdate) {
      contenidoModalUpdate = (
        <SolUpdateModalData sol={sol} onExeUpdate={a => this.onExeUpdate(a)} />
      );
    }

    const estatus = EstatusSol.dicLabel[sol.idEstatus];
    const estatusCSS = 'flexgrow  estatusSol_' + sol.idEstatus;

    const cmdEliminar = this.getCmdEliminar(sol);

    let dataInfo = null;

    if (sol.listaInfo) {
      dataInfo = sol.listaInfo.map((item, index) => {
        return (
          <p title={item.nombre} key={index}>
            {item.valor}
          </p>
        );
      });
    }

    return (
      <Fragment>
        <div id="solPage">
          <div className="colSol">
            <div className="divFlex">
              <Button
                className="cmdSol btn-small "
                title="Editar"
                onClick={event => this.onShowModalUpdate(sol)}
              >
                <i className="fas fa-edit" />
              </Button>
              <div>
                <h2 title="Cliente">
                  <span className="label label-primary">{sol.tipo}</span>
                  <span className="label label-black">{sol.nombreCliente}</span>
                </h2>
                <div className="wrapperDataInfo">{dataInfo}</div>
              </div>

              <div>
                <h2 className={estatusCSS}>{estatus}</h2>
                <h2 title="Vendedor" className="text-left">
                  <i className="fa fa-user mrBTN" />
                  {sol.nombreVendedor} / {sol.area}
                </h2>
              </div>
              {cmdEliminar}

              <Link
                className="cmdSol  black btn-small mrBTNXL"
                title="Cancelar"
                to={UrlPages.MIS_SOLICITUDES}
              >
                <i className="fas fa-backspace mrBTN" />
                Regresar
              </Link>
            </div>

            <div className="colWraperBrowser">
              <SolBrowser sol={sol} />
            </div>
          </div>
          <SolDictamen onCrudSol={this.props.onCrudSol} sol={sol} />
        </div>

        <Modal id="modalDeleteSol">
          {contenidoModal}
          <div>
            <Button className="red small" onClick={event => this.onExeDelete()}>
              Eliminar
            </Button>
          </div>
        </Modal>

        <Modal id="modalUpdateSol">{contenidoModalUpdate}</Modal>
      </Fragment>
    );
  }
}

export default SolPage;
