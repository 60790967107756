import React from 'react';
import HOCProtectedPage from '../../HOCProtectedPage';
import TopBar from '../../../pageComponents/topbar/TopBar';

import DataService from '../../../services/dataService/dataApi/DataApi';
import LibToast from '../../../lib/LibToast';
import { Button, Modal, Switch, TextInput, Textarea } from 'react-materialize';
import UrlPages from '../../UrlPages';
import LibValidacion from '../../../lib/LibValidacion';
import LibMsgFlash from '../../../services/msgFlash/LibMsgFlash';

const campo = {
  nombre: 'nombre',
  nick: 'nick',
  email: 'email',
  isAdmin: 'isAdmin',
  isActivo: 'isActivo',
  comentario: 'comentario'
};

const listaCamposReq = [campo.nombre, campo.nick, campo.email];

class UsuariosCrudPage extends React.Component {
  constructor(props, context) {
    super(props, context);

    const id = props.match.params.id || null;
    const ope = props.match.params.ope || null;

    this.state = {
      model: {
        isNew: true,
        nombre: '',
        nick: '',
        email: '',
        isAdmin: false,
        isActivo: true,
        comentario: ''
      },
      id,
      ope
    };

    this.isEnProceso = false;
    this.onSubmit.bind(this);
  }

  async componentDidMount() {
    const { id, ope } = this.state;

    if (ope === 'update') {
      //solicitar los datos del registro

      const respuesta = await DataService.Admin.Usuario.Detalle(id);

      if (!respuesta.success) {
        LibToast.alert(respuesta.msg);
        return;
      }

      const model = respuesta.data.usuario;

      //quitamos intentos login
      delete model.intentosLogin;

      this.setState({ model });

      LibToast.success('Datos recibidos  - ' + model.nick);
    }
  }

  onChangeText = (nuevoValor, propiedad) => {
    const model = { ...this.state.model };
    model[propiedad] = nuevoValor;
    this.setState({ model });
  };

  onChangeBool = (nuevoValorBool, propiedad) => {
    const model = { ...this.state.model };
    model[propiedad] = nuevoValorBool;
    this.setState({ model });
  };

  onCancel() {
    const url = UrlPages.ADM_USUARIO_INDEX;
    const model = this.state.model;

    LibMsgFlash.setMsgInfo(`${model.nick}`, model._id);
    this.props.history.push(url);
  }

  onShowModalDelete = () => {
    const modal = window.M.Modal.getInstance(
      document.getElementById('modalDeleteUsuario')
    );
    modal.open();
  };

  onSubmit = async event => {
    event.preventDefault();

    if (this.isEnProceso) {
      return;
    }

    //validar -----------------------------
    const model = this.state.model;

    const isValid = LibValidacion.objectTieneProp(model, listaCamposReq);
    if (isValid !== true) {
      LibToast.alert(isValid);
      return;
    }

    //Validar el email
    {
      const isValidEmail = LibValidacion.isEmailValid(model.email);
      if (!isValidEmail) {
        LibToast.alert('Email invalido');
        return;
      }
    }

    //hacer la actualizacion --------------

    this.isEnProceso = true;

    const id = model._id || null;

    let respuesta = null;

    if (id) {
      respuesta = await DataService.Admin.Usuario.Update(id, model);
    } else {
      respuesta = await DataService.Admin.Usuario.Create(model);
    }

    if (!respuesta.success) {
      this.isEnProceso = false;
      LibToast.alert(respuesta.msg);
      return;
    }

    let tagMsg = 'Se actualizó';

    if (model._id === undefined) {
      model._id = respuesta.data.usuario._id;
      tagMsg = 'Se creó';
    }

    LibMsgFlash.setMsgInfo(`${tagMsg} ${model.nick}`, model._id);
    const url = UrlPages.ADM_USUARIO_INDEX;
    this.props.history.push(url);
  };

  render() {
    const { model } = this.state;

    if (this.state.ope === null) {
      return null;
    }

    const label = model._id ? 'Actualizar Datos del Usuario' : 'Nuevo Usuario';
    let cmdDelete = null;

    if (model._id) {
      cmdDelete = (
        <Button
          className="red floatR mt-20 mrBTN"
          onClick={event => this.onShowModalDelete()}
        >
          <i className="fas fa-trash mrBTN" />
          Eliminar
        </Button>
      );
    }

    return (
      <HOCProtectedPage>
        <TopBar />

        {cmdDelete}

        <div className="panForm">
          <form onSubmit={event => this.onSubmit(event)} className="form">
            <h5 className="tituloTabla mb20">{label}</h5>

            <div className="row">
              <div className="col s6">
                <div className="row">
                  <div className="col s3 " style={{ textAlign: 'right' }}>
                    Esta activa la cuenta
                  </div>
                  <div className="col s6">
                    <Switch
                      id="switch-isActivo"
                      offLabel="No"
                      onLabel="Sí"
                      checked={model.isActivo}
                      onChange={event =>
                        this.onChangeBool(event.target.checked, 'isActivo')
                      }
                    />
                  </div>
                </div>

                <div className="row mt-20">
                  <TextInput
                    value={model.nombre}
                    s={12}
                    label="Nombre Completo"
                    onChange={event =>
                      this.onChangeText(event.target.value, campo.nombre)
                    }
                  />
                </div>

                <div className="row ">
                  <TextInput
                    value={model.nick}
                    s={12}
                    label="Nombre Corto (nick)"
                    onChange={event =>
                      this.onChangeText(event.target.value, campo.nick)
                    }
                  />
                </div>

                <div className="row ">
                  <TextInput
                    value={model.email}
                    s={12}
                    label="Email"
                    onChange={event =>
                      this.onChangeText(event.target.value, campo.email)
                    }
                  />
                </div>

                <div className="row">
                  <div className="col s3 " style={{ textAlign: 'right' }}>
                    Es Administrador
                  </div>
                  <div className="col s6">
                    <Switch
                      id="switch-isAdmin"
                      offLabel="No"
                      onLabel="Sí"
                      checked={model.isAdmin}
                      onChange={event =>
                        this.onChangeBool(event.target.checked, 'isAdmin')
                      }
                    />
                  </div>
                </div>

                <div className="divFlex mt-20">
                  <Button
                    className="waves-effect waves-light btn-small mrBTNXL"
                    onClick={event => this.onSubmit(event)}
                    title="Guardar"
                  >
                    <i className="fas fa-upload mrBTN" />
                    Guardar
                  </Button>

                  <Button
                    className="waves-effect waves-light btn-small black"
                    onClick={event => {
                      return this.onCancel();
                    }}
                    title="Cancelar"
                  >
                    <i className="fas fa-times mrBTN" />
                    Cancelar
                  </Button>
                </div>
              </div>

              <div className="col s6">
                <div className="row">
                  <Textarea
                    label="Comentario"
                    value={model.comentario}
                    s={12}
                    onChange={event =>
                      this.onChangeText(event.target.value, campo.comentario)
                    }
                  />
                </div>
              </div>
            </div>
          </form>
        </div>

        <Modal id="modalDeleteUsuario">
          <h6 className="tituloDelete">
            <i className="fas fa-trash mrBTN" /> Eliminar Usuario
          </h6>
          <h3>{model.nombre}</h3>
          <div>
            <Button className="red small" onClick={event => this.onExeDelete()}>
              <i className="fas fa-trash mrBTN" /> Eliminar Cuenta del Usuario
            </Button>
          </div>
        </Modal>
      </HOCProtectedPage>
    );
  }

  onExeDelete = async () => {
    if (this.isEnProceso) {
      return;
    }

    const model = this.state.model;

    this.isEnProceso = true;

    const id = model._id;
    const respuesta = await DataService.Admin.Usuario.Delete(id);

    if (!respuesta.success) {
      this.isEnProceso = false;
      LibToast.alert(respuesta.msg);
      return;
    }

    LibMsgFlash.setMsgInfo('Se eliminó ' + model.nombre, model._id);

    const url = UrlPages.ADM_USUARIO_INDEX;
    this.props.history.push(url);
  };
}

export default UsuariosCrudPage;
