const KEY = 'solmc_flash';
const KEY_ID = 'solmc_lastid';

const LibMsgFlash = {
  saveLastID: function(idLAST) {
    if (idLAST !== null) {
      window.localStorage.setItem(KEY_ID, idLAST);
    }
  },
  setMsgSuccess: (msg, idLAST = null) => {
    const dataFlash = JSON.stringify({ msg, tipo: 'success' });
    window.localStorage.setItem(KEY, dataFlash);

    LibMsgFlash.saveLastID(idLAST);
  },
  setMsgAlert: (msg, idLAST = null) => {
    const dataFlash = JSON.stringify({ msg, tipo: 'alert' });
    window.localStorage.setItem(KEY, dataFlash);

    LibMsgFlash.saveLastID(idLAST);
  },
  setMsgInfo: (msg, idLAST = null) => {
    const dataFlash = JSON.stringify({ msg, tipo: 'info' });
    window.localStorage.setItem(KEY, dataFlash);

    LibMsgFlash.saveLastID(idLAST);
  },
  getMsg: () => {
    let dataFlash = window.localStorage.getItem(KEY);

    if (!dataFlash) {
      return null;
    }

    window.localStorage.removeItem(KEY);

    dataFlash = JSON.parse(dataFlash);
    return dataFlash;
  },
  getIdLast: () => {
    let dataFlash = window.localStorage.getItem(KEY_ID);

    if (!dataFlash) {
      return null;
    }

    window.localStorage.removeItem(KEY_ID);

    return dataFlash;
  }
};

export default LibMsgFlash;
