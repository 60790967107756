import React, { Component, Fragment } from 'react';
import AppContext from '../../app/AppContext';
import TopBar from '../../pageComponents/topbar/TopBar';
import HOCProtectedPage from '../HOCProtectedPage';
import SolPage from './SolPage';
import DataService from '../../services/dataService/dataApi/DataApi';
import LibToast from '../../lib/LibToast';

class SolPageHoc extends Component {
  constructor(props, context) {
    super(props, context);

    const id = props.match.params.id;

    this.state = {
      id,
      sol: null
    };
  }

  async componentDidMount() {
    const id = this.state.id;

    let solSelected = null;

    if (this.context.listaSol) {
      solSelected = this.context.listaSol.find(item => {
        return item._id === id;
      });
    }

    if (solSelected === undefined || solSelected === null) {
      //busar la sol

      const respuesta = await DataService.Mc.getSol(id);

      if (respuesta.success) {
        LibToast.info('Datos recibidos solicitud ' + id);
        solSelected = respuesta.data;
      } else {
        LibToast.alert(respuesta.msg);
        solSelected = null;
      }
    }

    this.setState({ sol: solSelected });
  }

  render() {
    const { id, sol } = this.state;

    if (!id || !sol) {
      return null;
    }

    return (
      <HOCProtectedPage>
        <AppContext.Consumer>
          {context => (
            <Fragment>
              <TopBar />
              <SolPage sol={sol} onCrudSol={context.onCrudSol} />
            </Fragment>
          )}
        </AppContext.Consumer>
      </HOCProtectedPage>
    );
  }
}

export default SolPageHoc;
