import React, {Component} from 'react';
import {Button} from 'react-materialize';
import './ImgBrowser.css';
import DataService from '../../services/dataService/DataService';
import LibToast from '../../lib/LibToast';

class ImgBrowser extends Component {
  constructor(props, context) {
    super(props, context);
    this.state = {
      v: 0,
      listaImg: props.listaImg
    };
  }

  onOpenImageNewWindows = img => {
    window.open(img.url, 'Documento Solicitud', 'resizable,scrollbars');
  };

  onRotarImagen = async itemImg => {
    const v = this.state.v + 1;

    const lista = this.state.listaImg.map(item => {
      //hacer la rotacion

      if (item.file !== itemImg.file) {
        return item;
      }

      item.isRotacion = item.isRotacion === undefined ? 0 : item.isRotacion;
      item.isRotacion++;

      return item;
    });

    this.setState({v, lista});
  };

  render() {
    const v = this.state.v;

    const numFotos = this.state.listaImg.length;

    const lista = this.props.listaImg.map((item, index) => {
      const cssImg =
        'imgBrowserDoc ' +
        (item.isRotacion > 0 ? 'imgr' + item.isRotacion.toString() : '');


      const fileName = item.file.toLowerCase();
      const isPDF = fileName.endsWith('pdf');

      let docControl = null;
      let btn1=null;
      let btn2=null;
      let tagDoc=null;

      if (isPDF) {
        docControl = <iframe src={item.url} className="embedPDF" key={index}/>;
        tagDoc='PDF';
      } else {
        docControl = <img src={item.url} alt="Documento" className={cssImg} key={index}/>;
        btn1= <Button
          className="cmdRotar btn-small black"
          title="Girar a la derecha"
          onClick={event => {
            this.onRotarImagen(item);
          }}
        >
          <i className="fas fa-redo"/>
        </Button>;

        btn2= <Button
          className="cmdOpen btn-small black"
          title="Abrir en nueva ventana"
          onClick={event => {
            this.onOpenImageNewWindows(item);
          }}
        >
          <i className="fas fa-window-restore"/>
        </Button>;
        tagDoc='Foto';
      }


      return (
        <div className="wrapperImgDoc" key={index}>
          {btn1}

          {btn2}

          <h4 className="tituloSolBrowser">
            {tagDoc} {index + 1} / {numFotos}
          </h4>

          {docControl}
        </div>
      );
    });

    return <div>{lista}</div>;
  }
}

export default ImgBrowser;
