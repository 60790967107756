import React from 'react';
import HOCProtectedPage from '../../HOCProtectedPage';
import TopBar from '../../../pageComponents/topbar/TopBar';

import DataService from '../../../services/dataService/dataApi/DataApi';
import LibToast from '../../../lib/LibToast';
import { Button, Modal, Switch, Textarea, TextInput } from 'react-materialize';
import UrlPages from '../../UrlPages';
import LibValidacion from '../../../lib/LibValidacion';
import LibMsgFlash from '../../../services/msgFlash/LibMsgFlash';
import DocTipoForm from './DocTipoForm';
import DocInfoForm from './DocInfoForm';

const campo = {
  tipo: 'tipo',
  listaDocTipo: 'listaDocTipo',
  listaInfo: 'listaInfo',
  isActivo: 'isActivo',
  comentario: 'comentario',
  isPermitirEnWebsite: 'isPermitirEnWebsite'
};

class SolicitudTipoCrudPage extends React.Component {
  constructor(props, context) {
    super(props, context);

    const id = props.match.params.id || null;
    const ope = props.match.params.ope || null;

    this.state = {
      model: {
        listaDocTipo: [],
        listaInfo: [],
        isActivo: true,
        comentario: '',
        tipo: '',
        isPermitirEnWebsite: false
      },
      id,
      ope,
      isShowModalDelete: false
    };

    this.isEnProceso = false;
    this.onSubmit.bind(this);
  }

  async componentDidMount() {
    const { id, ope } = this.state;

    if (ope === 'update') {
      //solicitar los datos del registro
      const respuesta = await DataService.Admin.SolTipo.Detalle(id);

      if (!respuesta.success) {
        LibToast.alert(respuesta.msg);
        return;
      }

      const model = respuesta.data.solTipo;

      LibToast.success('Datos recibidos - ' + model.tipo);
      this.setState({ model });
    }
  }

  onChangePropiedad = (nuevoValor, propiedad) => {
    const model = { ...this.state.model };
    model[propiedad] = nuevoValor;
    this.setState({ model });
  };

  onCancel() {
    const url = UrlPages.ADM_SOLTIPO_INDEX;
    const model = this.state.model;

    LibMsgFlash.setMsgInfo(`${model.tipo}`, model._id);
    this.props.history.push(url);
  }

  onShowModalDelete = () => {
    const modal = window.M.Modal.getInstance(
      document.getElementById('modalDeleteSolTipo')
    );
    modal.open();
  };

  onSubmit = async event => {
    if (this.isEnProceso) {
      event.preventDefault();
      return;
    }

    //validar -----------------------------
    const model = this.state.model;
    const listaP = ['tipo'];
    const isValid = LibValidacion.objectTieneProp(model, listaP);
    if (isValid !== true) {
      LibToast.alert(isValid);
      return;
    }

    //validar que hay documentyos
    const contador = model.listaDocTipo.filter(item => {
      return !item.isNoRequerido;
    }).length;

    if (contador === 0) {
      LibToast.alert(
        'Se requieren registrar al menos un documento obligatorio en la solicitud'
      );
      return;
    }

    this.isEnProceso = true;

    //hacer la actualizacion --------------
    const id = model._id || null;

    let respuesta = null;

    model.listaDocTipo = model.listaDocTipo.map(item => {
      if (item._id === 'new') {
        delete item._id;
        return item;
      }

      return item;
    });

    if (id) {
      respuesta = await DataService.Admin.SolTipo.Update(id, model);
    } else {
      respuesta = await DataService.Admin.SolTipo.Create(model);
    }

    if (!respuesta.success) {
      this.isEnProceso = false;
      LibToast.alert(respuesta.msg);
      return;
    }

    let tagMsg = 'Se actualizó';

    if (model._id === undefined) {
      //caso de ser nuevo refgistro
      model._id = respuesta.data.solTipo._id;
      tagMsg = 'Se creó';
    }

    LibMsgFlash.setMsgInfo(`${tagMsg} ${model.tipo}`, model._id);
    const url = UrlPages.ADM_SOLTIPO_INDEX;
    this.props.history.push(url);

    event.preventDefault();
  };

  onChangeBool = (nuevoValorBool, propiedad) => {
    const model = { ...this.state.model };
    model[propiedad] = nuevoValorBool;

    this.setState({ model });
  };

  render() {
    if (this.state.ope !== 'update' && this.state.ope !== 'create') {
      return null;
    }

    const model = this.state.model;
    const label = model._id
      ? 'Actualizar Tipo de Solicitud'
      : 'Nuevo Tipo de Solicitud';

    let cmdDelete = null;

    if (model._id) {
      cmdDelete = (
        <Button
          className="red floatR mt-20 mrBTN"
          onClick={event => this.onShowModalDelete()}
        >
          <i className="fas fa-trash mrBTN" />
          Eliminar
        </Button>
      );
    }

    return (
      <HOCProtectedPage>
        <TopBar />

        {cmdDelete}

        <form onSubmit={event => this.onSubmit(event)} className=" panForm">
          <h5 className="tituloTabla mb20">{label}</h5>

          <div className="row">
            <div className="col s4">
              <div className="row ">
                <TextInput
                  value={model.tipo}
                  s={12}
                  label="Tipo de Solicitud"
                  onChange={event =>
                    this.onChangePropiedad(event.target.value, campo.tipo)
                  }
                />
              </div>

              <div className="row mb-20 ml-10">
                <div>¿Está disponible en website (tiendatelcel.com.mx) ?</div>

                <Switch
                  id="switch-isGestor"
                  offLabel="No"
                  onLabel="Sí"
                  checked={model.isPermitirEnWebsite}
                  onChange={event =>
                    this.onChangeBool(
                      event.target.checked,
                      'isPermitirEnWebsite'
                    )
                  }
                />
              </div>
              <div className="row">
                <Textarea
                  label="Comentario"
                  value={model.comentario}
                  s={12}
                  onChange={event =>
                    this.onChangePropiedad(event.target.value, campo.comentario)
                  }
                />
              </div>
            </div>
            <div className="col s8" style={{ marginTop: -33 }}>
              <DocTipoForm
                lista={model.listaDocTipo}
                onChangeModel={listaNew =>
                  this.onChangePropiedad(listaNew, campo.listaDocTipo)
                }
              />

              <div className="mt-50">
                <DocInfoForm
                  lista={model.listaInfo}
                  onChangeModel={listaNew =>
                    this.onChangePropiedad(listaNew, campo.listaInfo)
                  }
                />
              </div>
            </div>
          </div>

          <div className="divFlex">
            <Button
              className="waves-effect waves-light btn-small mrBTNXL"
              onClick={this.onSubmit}
              title="Guardar"
            >
              <i className="fas fa-upload mrBTN" />
              Guardar
            </Button>

            <Button
              className="waves-effect waves-light btn-small black"
              onClick={event => {
                return this.onCancel();
              }}
              title="Cancelar"
            >
              <i className="fas fa-times mrBTN" />
              Cancelar
            </Button>
          </div>
        </form>

        <Modal id="modalDeleteSolTipo">
          <h6 className="tituloDelete">
            <i className="fas fa-trash mrBTN" /> Eliminar Tipo de Solicitud
          </h6>
          <h3>{model.tipo}</h3>
          <div>
            <Button className="red small" onClick={event => this.onExeDelete()}>
              <i className="fas fa-trash mrBTN" /> Eliminar
            </Button>
          </div>
        </Modal>
      </HOCProtectedPage>
    );
  }

  onExeDelete = async () => {
    //ejecutar la eliminarcion
    if (this.isEnProceso) {
      return;
    }

    this.isEnProceso = true;

    const id = this.state.model._id;
    const respuesta = await DataService.Admin.SolTipo.Delete(id);

    if (!respuesta.success) {
      this.isEnProceso = false;
      LibToast.alert(respuesta.msg);
      return;
    }

    LibMsgFlash.setMsgInfo('Se eliminó ' + this.state.model.tipo, id);

    const url = UrlPages.ADM_SOLTIPO_INDEX;
    this.props.history.push(url);
  };
}

export default SolicitudTipoCrudPage;
