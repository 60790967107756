import ConfigApp from '../dataService/dataApi/configApi';
import AuthService from '../authService/AuthService';

const isDebug = true;

const getData = (sol, tipo) => {
  const idSession = AuthService.getUser().idSession;

  return {
    origen: 'mc',
    usuario: AuthService.getUser().usuario,
    idSession,
    tipo,
    idSol: sol._id,
    solTipo: sol.tipo,
    solEstatus: sol.estatus,
    nickVendedor: sol.nick,
    nombreCliente: sol.nombreCliente,
    idArea: sol.idArea,
    area: sol.area,
    idUsuario: sol.idUsuario
  };
};

const socket = window.io.connect(ConfigApp.urlWS, { forceNew: true });

//{tipo:'delete, run(idSol)}
const listaCallback = [];

socket.on('m', function(data) {
  if (isDebug) {
    console.log('m', data);
  }

  if (data.isTest) {
    return;
  }

  if (!AuthService.getIsAuthenticated()) {
    return;
  }

  const idSession = AuthService.getUser().idSession;

  if (data.idSession === idSession) {
    //No procesamois los mensajes del mismo usaurio
    if (isDebug) {
      console.log('salir - mensaje del mismo usuario');
    }
    return;
  }

  listaCallback.forEach(async ocb => {
    if (ocb.tipo === data.tipo) {
      await ocb.run(data);
    }
  });
});

const LibMsgWSService = {
  addCBEvent: (tipo, callback) => {
    listaCallback.push({ tipo, run: callback });
  },
  sol: {
    delete: sol => {
      const data = getData(sol, 'sol_delete');
      socket.emit('m', data);
    },
    update: (sol, dataUpdate) => {
      const data = getData(sol, 'sol_update');
      data.dataUpdate = data;
      socket.emit('m', data);
    },
    cambioEstatus: (sol, estatus, comentario) => {
      const data = getData(sol, 'sol_cambioEstatus');

      data.estatus = estatus;
      data.com = comentario;

      socket.emit('m', data);
    },
    comentario: (sol, comentario) => {
      const data = getData(sol, 'sol_comentario');
      data.com = comentario;
      socket.emit('m', data);
    }
  }
};

export default LibMsgWSService;
