import React, { Fragment } from 'react';
import HOCProtectedPage from '../HOCProtectedPage';
import TopBar from '../../pageComponents/topbar/TopBar';
import AppContext from '../../app/AppContext';

import AllSolicitudesPage from './AllSolicitudesPage';

// todas las solicitudes *****************************

class AllSolicitudesPageHOC extends React.Component {
  render() {
    return (
      <HOCProtectedPage>
        <AppContext.Consumer>
          {context => (
            <Fragment>
              <TopBar />
              <AllSolicitudesPage
                listaSol={context.listaSol}
                onCrudSol={context.onCrudSol}
              />
            </Fragment>
          )}
        </AppContext.Consumer>
      </HOCProtectedPage>
    );
  }
}

export default AllSolicitudesPageHOC;
