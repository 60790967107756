import React from 'react';
import HOCProtectedPage from '../HOCProtectedPage';
import TopBar from '../../pageComponents/topbar/TopBar';

function DashboardPage() {
  return (
    <HOCProtectedPage>
      <>
        <TopBar />
        Im dashb protected
      </>
    </HOCProtectedPage>
  );
}

export default DashboardPage;
