import React, { Component } from 'react';
import LibToast from '../../lib/LibToast';

class FormRecuperacionExe extends Component {
  constructor(props, context) {
    super(props, context);
    this.state = {
      email: props.email,
      password: '',
      passwordConfirmacion: '',
      isEnProceso: props.isEnProceso
    };
  }

  onTxtChange = (campo, valor) => {
    let dic = {};
    dic[campo] = valor;
    this.setState(dic);

    if (campo === 'email') {
      this.props.onUpdateEmail(valor);
    }
  };

  onSubmit = async event => {
    event.preventDefault();

    const token = this.state.token.trim();
    const pass = this.state.password.trim();
    const passConfirm = this.state.passwordConfirmacion.trim();

    let isValid = true;

    if (token === '') {
      LibToast.alert('Falta el Token');
      isValid = false;
    }

    if (pass === '') {
      LibToast.alert('Falta el Password');
      isValid = false;
    }

    if (passConfirm === '') {
      LibToast.alert('Falta la confirmación del Password');
      isValid = false;
    }

    if (passConfirm !== pass) {
      LibToast.alert('La confirmación del password es incorrecta');
      isValid = false;
    }

    if (!isValid) {
      return;
    }

    await this.props.onSubmitForm(token, pass);
  };

  render() {
    const iconLoading = this.props.isEnProceso && (
      <div className="center-align">
        <i className="fa fa-cog fa-spin " />
        <span className="pl-1"> En Proceso</span>
      </div>
    );

    return (
      <form onSubmit={this.onSubmit} className="login-form">
        <div className="">
          <p>Hola</p>
          <h4 className="tituloRow">{this.props.email}</h4>
          <p>
            Se ha enviado <strong>a tu correo</strong> un código de autorización
            para indicar tu password.
          </p>
          <p>Este código tiene una validez de 15 minutos</p>
          <p>
            Consulta tu correo para obtener el código e indicar el nuevo
            password
          </p>
          <p>Tienes 3 intentos para poner correctamente el token.</p>
        </div>
        <div className="row margin">
          <div className="input-field col s12">
            <i className="fa fa-user prefix" />

            <input
              id="token"
              value={this.state.token}
              onChange={event => this.onTxtChange('token', event.target.value)}
              type="text"
            />
            <label htmlFor="email" className="center-align active">
              TOKEN de Autorización
            </label>
          </div>
        </div>

        <div className="row margin">
          <div className="input-field col s12">
            <i className="fas fa-key prefix" />
            <input
              id="password"
              value={this.state.password}
              onChange={event =>
                this.onTxtChange('password', event.target.value)
              }
              type="password"
            />
            <label htmlFor="password">Nuevo Password</label>
          </div>
        </div>

        <div className="row margin">
          <div className="input-field col s12">
            <i className="fas fa-key prefix" />
            <input
              id="passwordConfirm"
              value={this.state.passwordConfirmacion}
              onChange={event =>
                this.onTxtChange('passwordConfirmacion', event.target.value)
              }
              type="password"
            />
            <label htmlFor="passwordConfirm">
              Confirmación del Nuevo Password
            </label>
          </div>
        </div>

        <div className="row">
          <div className="input-field col s12">
            <button className="btn waves-effect waves-light border-round col s12">
              Actualizar Password
            </button>
          </div>
        </div>

        {iconLoading}

        <div className="row">
          <div className="input-field col s12">
            <p className="margin right-align medium-small">
              <span
                className="linkRecordar"
                onClick={event => this.props.onToogleForm()}
              >
                Olvide mi password
                <i className="fas fa-arrow-circle-right ml-5" />
              </span>
            </p>
          </div>
        </div>
      </form>
    );
  }
}

export default FormRecuperacionExe;
