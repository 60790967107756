import React from 'react';
import { Link } from 'react-router-dom';
import UrlPages from '../../../pages/UrlPages';
import { Dropdown } from 'react-materialize';

function FactoryMenuMC(isMC) {
  if (!isMC) {
    return null;
  }

  return (
    <li>
      <Dropdown
        trigger={
          <span className="w180px aNavWithIcon">
            <>
              <i className="fas fa-folder-open" />
              Mis Solicitudes
            </>
          </span>
        }
        className="w180px iconsR20"
      >
        <Link to={UrlPages.MIS_SOLICITUDES}>Por Evaluar</Link>
        <Link to={UrlPages.ALL_SOLICITUDES}>Todas las Solicitudes</Link>
        <Link to={UrlPages.DASHBOARD}>Resumen</Link>
      </Dropdown>
    </li>
  );
}

export default FactoryMenuMC;
