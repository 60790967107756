import React from 'react';
import HOCProtectedPage from '../../HOCProtectedPage';
import TopBar from '../../../pageComponents/topbar/TopBar';

import AreaVentasPage from './AreaVentasPage';

function AreaVentasPageHOC() {
  return (
    <HOCProtectedPage>
      <TopBar />
      <AreaVentasPage />
    </HOCProtectedPage>
  );
}

export default AreaVentasPageHOC;
